import { createApiThunk } from "@redux/api/createApiThunk";
export var fetchAutocompleteSuggestions = createApiThunk({
    method: "GET",
    route: "suggestions/autocomplete",
    component: "AUTOCOMPLETE_SUGGESTIONS"
});
export var fetchSuggestionsForMainFeed = createApiThunk({
    method: "GET",
    route: "suggestions/interests",
    component: "FEED_FILTERS_GET_SUGGESTIONS_MAIN",
    version: 2
});
export var fetchSuggestionsForLandingPage = createApiThunk({
    method: "GET",
    route: "suggestions/:suggestionType/:lpType/:lpId",
    component: "FEED_FILTERS_GET_SUGGESTIONS_LP",
    version: 2
});
