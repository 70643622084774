import { createApiThunk } from "@redux/api/createApiThunk";
export var fetchPhotoByImg = createApiThunk({
    method: "GET",
    route: "photo/shared/:img",
    component: "FETCH_PHOTO",
    version: 2,
    takeLatest: true
});
export var fetchPhotosByShareLink = createApiThunk({
    method: "GET",
    route: "photo/shared/:urlSlug",
    component: "FETCH_PHOTOS_FROM_SHARE_LINK"
});
export var generateShareLinkForPhotos = createApiThunk({
    method: "POST",
    route: "photo/shorten",
    component: "GENERATE_SHARE_LINK_FOR_PHOTOS",
});
export var generateShareOfShelf = createApiThunk({
    method: "POST",
    route: "photo/share_of_shelf",
    component: "GENERATE_SHARE_OF_SHELF"
});
export var logSharedPhotos = createApiThunk({
    method: "POST",
    route: "photo/share",
    component: "LOG_PHOTO_SHARE",
});
export var logDownloadedPhoto = createApiThunk({
    method: "POST",
    route: "photo/download",
    component: "LOG_PHOTO_DOWNLOAD",
});
export var addTagsToPhoto = createApiThunk({
    method: "POST",
    route: "photo/tags",
    component: "ADD_TAGS_TO_PHOTO",
    loadingToast: "Adding tag...",
    successToast: "Tag added!"
});
export var removeTagsFromPhoto = createApiThunk({
    method: "POST",
    route: "photo/tags/update",
    component: "REMOVE_TAGS_FROM_PHOTO",
    loadingToast: "Removing tag...",
    successToast: "Tag removed!"
});
export var addPhotoView = createApiThunk({
    method: "POST",
    route: "photo/view",
    component: "PHOTO_VIEW"
});
export var fetchStories = createApiThunk({
    method: 'GET',
    route: 'photos/stories',
    component: 'STORIES'
});
