var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPlanogramsByShareLink } from "@redux/api/endpoints/planogram";
import { addTagsToPhoto, fetchPhotosByShareLink, removeTagsFromPhoto } from "@redux/api/endpoints/photos";
import { addTagsToFlyer, fetchFlyersByShareLink, removeTagsFromFlyer } from "@redux/api/endpoints/flyers";
import { photosLinkedToCollections, photoUnlinkedFromCollection } from "@redux/slices/userCollectionsSlice";
import { logout } from "@redux/actions/logout";
var initialState = null;
var isRemoveTagThunk = isAnyOf(removeTagsFromPhoto.fulfilled, removeTagsFromFlyer.fulfilled);
var isAddTagThunk = isAnyOf(addTagsToPhoto.fulfilled, addTagsToFlyer.fulfilled);
var isEditTagThunk = isAnyOf(isRemoveTagThunk, isAddTagThunk);
export var galleryPageSlice = createSlice({
    name: "galleryPage",
    initialState: initialState,
    reducers: { /* No reducers */},
    extraReducers: function (builder) {
        builder
            .addCase(fetchPlanogramsByShareLink.fulfilled, function (_state, action) {
            return __assign({ type: "planogram" }, action.payload);
        })
            .addCase(fetchPhotosByShareLink.fulfilled, function (_state, action) {
            if (action.payload.error) {
                return __assign({ type: "photo" }, action.payload);
            }
            return __assign(__assign({ type: "photo" }, action.payload), { data: action.payload.data.map(function (photo) { return (__assign(__assign({}, photo), { planogram: photo.planogram_imgs, categories: photo.tags.filter(function (x) { return x.type === "categories"; }), collections: photo.tags.filter(function (x) { return x.type === "collections"; }), companies: photo.tags.filter(function (x) { return x.type === "companies"; }), tags: photo.tags.filter(function (x) { return x.type === "tags"; }) })); }) });
        })
            .addCase(fetchFlyersByShareLink.fulfilled, function (_state, action) {
            if (action.payload.error) {
                return __assign({ type: "flyer" }, action.payload);
            }
            return __assign(__assign({ type: "flyer" }, action.payload), { data: action.payload.data.map(function (flyer) { return (__assign(__assign({}, flyer), { categories: flyer.tags.filter(function (x) { return x.type === "categories"; }), companies: flyer.tags.filter(function (x) { return x.type === "companies"; }), tags: flyer.tags.filter(function (x) { return x.type === "tags"; }) })); }) });
        })
            .addCase(photosLinkedToCollections, function (state, action) {
            if ((state === null || state === void 0 ? void 0 : state.type) !== "photo" || state.error) {
                return;
            }
            var linkedPhotoIds = action.payload.photos.map(function (photo) { return photo.id; });
            var collectionTags = action.payload.collections.map(function (_a) {
                var img = _a.img, name = _a.name, primary_color = _a.primary_color, id = _a.id;
                return ({
                    type: "collections",
                    img: img,
                    name: name,
                    primary_color: primary_color,
                    url_slug: id,
                });
            });
            state.data
                .filter(function (photo) { return linkedPhotoIds.includes(photo.id); })
                .forEach(function (photo) {
                var _a;
                var currentCollections = photo.collections.map(function (collection) { return collection.url_slug; });
                var newCollections = collectionTags.filter(function (newTag) {
                    return !currentCollections.includes(newTag.url_slug);
                });
                (_a = photo.collections).push.apply(_a, newCollections);
            });
        })
            .addCase(photoUnlinkedFromCollection, function (state, action) {
            if ((state === null || state === void 0 ? void 0 : state.type) === "photo" && !state.error) {
                var updatedPhoto = state.data.find(function (photo) {
                    return photo.id === action.payload.photo_id;
                });
                if (updatedPhoto) {
                    updatedPhoto.collections = updatedPhoto.collections.filter(function (collection) {
                        return collection.url_slug !== action.payload.collection_id;
                    });
                }
            }
        })
            .addCase("PHOTO_SAVE_UPDATE_LOCALLY", function (state, action) {
            var _a = action.data, photo_id = _a.photo_id, saved = _a.saved;
            if ((state === null || state === void 0 ? void 0 : state.type) === "photo" && !state.error) {
                var updatedPhoto = state.data.find(function (photo) {
                    return photo.id === photo_id;
                });
                if (updatedPhoto) {
                    updatedPhoto.saved = saved;
                }
            }
        })
            .addCase(logout, function () {
            return initialState;
        })
            .addMatcher(isEditTagThunk, function (state, action) {
            if (!state || state.error || state.type === "planogram") {
                return;
            }
            var _a = action.meta.arg.body, photo_id = _a.photo_id, updatedTag = __rest(_a, ["photo_id"]);
            var tagType = updatedTag.type;
            var updatedItem = state.data.find(function (item) {
                return item.id === photo_id;
            });
            if (updatedItem) {
                if (isAddTagThunk(action)) {
                    var tags = updatedItem[tagType];
                    if (!tags.find(function (tag) { return tag.url_slug === updatedTag.url_slug; })) {
                        tags.push(updatedTag);
                    }
                }
                else {
                    updatedItem[tagType] = updatedItem[tagType].filter(function (tag) {
                        return tag.url_slug !== updatedTag.url_slug;
                    });
                }
            }
        });
    }
});
export default galleryPageSlice.reducer;
