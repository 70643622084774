export var handleKeyboardClick = function (event, clickAction, cancelAction) {
    if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        clickAction();
    }
    else if (event.key === "Escape" && typeof cancelAction === "function") {
        event.preventDefault();
        cancelAction();
    }
};
