import { createApiThunk } from "@redux/api/createApiThunk";
export var fetchFlyerByImg = createApiThunk({
    method: "GET",
    route: "flyers/shared/:img",
    component: "FETCH_FLYER",
    version: 2,
    takeLatest: true
});
export var fetchFlyersByShareLink = createApiThunk({
    method: "GET",
    route: "flyers/shared/:urlSlug",
    component: "FETCH_FLYERS_FROM_SHARE_LINK"
});
export var generateShareLinkForFlyers = createApiThunk({
    method: "POST",
    route: "flyers/shorten",
    component: "GENERATE_SHARE_LINK_FOR_FLYERS",
});
export var addTagsToFlyer = createApiThunk({
    method: "POST",
    route: "flyers/tags",
    component: "ADD_TAGS_TO_FLYER",
    loadingToast: "Adding tag...",
    successToast: "Tag added!"
});
export var removeTagsFromFlyer = createApiThunk({
    method: "POST",
    route: "flyers/tags/update",
    component: "REMOVE_TAGS_FROM_FLYER",
    loadingToast: "Removing tag...",
    successToast: "Tag removed!"
});
