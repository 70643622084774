import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { generateShareOfShelf } from "@redux/api/endpoints/photos";
import { hideModal, showModal } from "@redux/slices/modalSlice";
var initialState = {
    data: null,
    filters: {
        companyType: "brand",
        chartType: "pie",
        metric: "count"
    },
};
var shareOfShelfSlice = createSlice({
    name: "shareOfShelf",
    initialState: initialState,
    reducers: {
        shareOfShelfFilterSelected: function (state, action) {
            state.filters[action.payload.filter] = action.payload.selectedOption;
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(generateShareOfShelf.fulfilled, function (state, action) {
            state.data = action.payload;
        })
            .addMatcher(isAnyOf(showModal, hideModal), function () {
            return initialState;
        });
    }
});
export default shareOfShelfSlice.reducer;
export var shareOfShelfFilterSelected = shareOfShelfSlice.actions.shareOfShelfFilterSelected;
