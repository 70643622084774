import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Arcade from "components/arcade";
import Loadable from "@loadable/component";
import { LoadingComponent } from "constants/functions";
import { loadable } from "react-lazily/loadable";
var FeedFilters = loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedFilters" */ "components/modal/body/feed-filters/FeedFilters"); }, { fallback: _jsx(LoadingComponent, {}) }).FeedFilters;
var FeedSort = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedSort" */ "components/modal/body/feed-sort"); }, { fallback: _jsx(LoadingComponent, {}) });
var FeedLayoutSettings = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedLayoutSettings" */ "components/modal/body/feed-layout"); }, { fallback: _jsx(LoadingComponent, {}) });
var Collection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalCollection" */ "components/modal/body/collection"); }, { fallback: _jsx(LoadingComponent, {}) });
var Login = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLogin" */ "components/modal/body/login"); }, { fallback: _jsx(LoadingComponent, {}) });
var Delete = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalDelete" */ "components/modal/body/delete"); }, { fallback: _jsx(LoadingComponent, {}) });
var SendFeedback = Loadable(function () { return import(/* webpackChunkName: "dynamic-SendFeedback" */ "components/modal/body/send-feedback"); }, { fallback: _jsx(LoadingComponent, {}) });
var WordCloud = Loadable(function () { return import(/* webpackChunkName: "dynamic-WordCloud" */ "components/modal/body/word-cloud"); }, { fallback: _jsx(LoadingComponent, {}) });
var KeyboardShortcuts = loadable(function () { return import(/* webpackChunkName: "dynamic-KeyboardShortcutsModal" */ "components/modal/body/keyboard-shortcuts/KeyboardShortcuts"); }, { fallback: _jsx(LoadingComponent, {}) }).KeyboardShortcuts;
var PhotoResponseGroup = Loadable(function () { return import(/* webpackChunkName: "dynamic-PhotoResponseGroup" */ "components/modal/body/photo-response-group"); }, { fallback: _jsx(LoadingComponent, {}) });
var TrendsLineInfo = Loadable(function () { return import(/* webpackChunkName: "dynamic-TrendsLineInfo" */ "components/modal/body/trends/line-chart"); }, { fallback: _jsx(LoadingComponent, {}) });
var TrendsVisualInfo = Loadable(function () { return import(/* webpackChunkName: "dynamic-TrendsVisualInfo" */ "components/modal/body/trends/visual"); }, { fallback: _jsx(LoadingComponent, {}) });
var WatchAVideo = Loadable(function () { return import(/* webpackChunkName: "dynamic-WatchAVideo" */ "components/modal/body/watch-a-video"); }, { fallback: _jsx(LoadingComponent, {}) });
var AnalyticsLearnMore = loadable(function () { return import(/* webpackChunkName: "dynamic-AnalyticsLearnMore" */ "components/modal/body/analytics-learn-more/AnalyticsLearnMore"); }, { fallback: _jsx(LoadingComponent, {}) }).AnalyticsLearnMore;
var InviteYourTeam = Loadable(function () { return import(/* webpackChunkName: "dynamic-InviteYourTeam" */ "components/modal/body/invite-your-team"); }, { fallback: _jsx(LoadingComponent, {}) });
var SwitchPrivateSpace = Loadable(function () { return import(/* webpackChunkName: "dynamic-SwitchPrivateSpace" */ "components/modal/body/switch-private-space"); }, { fallback: _jsx(LoadingComponent, {}) });
var CoverageModel = Loadable(function () { return import(/* webpackChunkName: "dynamic-CoverageModel" */ "components/modal/body/coverage-model"); }, { fallback: _jsx(LoadingComponent, {}) });
var LoginDisabled = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLoginDisabled" */ "components/modal/body/login-disabled"); }, { fallback: _jsx(LoadingComponent, {}) });
var DateFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalDateFilter" */ "components/modal/body/pricing-filters/date"); }, { fallback: _jsx(LoadingComponent, {}) });
var FeaturesFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeaturesFilter" */ "components/modal/body/pricing-filters/features"); }, { fallback: _jsx(LoadingComponent, {}) });
var LocationFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLocationFilter" */ "components/modal/body/pricing-filters/location"); }, { fallback: _jsx(LoadingComponent, {}) });
var ProductFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalProductFilter" */ "components/modal/body/pricing-filters/product"); }, { fallback: _jsx(LoadingComponent, {}) });
var PriceFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPriceFilter" */ "components/modal/body/pricing-filters/price"); }, { fallback: _jsx(LoadingComponent, {}) });
var PositionFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPositionFilter" */ "components/modal/body/pricing-filters/position"); }, { fallback: _jsx(LoadingComponent, {}) });
var TextBlob = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalTextBlob" */ "components/modal/body/text-blob"); }, { fallback: _jsx(LoadingComponent, {}) });
var EmailDomain = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalEmailDomain" */ "components/modal/body/email-domain"); }, { fallback: _jsx(LoadingComponent, {}) });
var SuggestMissingData = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalSuggestMissingData" */ "components/modal/body/suggest-missing-data"); }, { fallback: _jsx(LoadingComponent, {}) });
var StudioTextInput = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalStudioTextInput" */ "components/modal/body/studio-text-input"); }, { fallback: _jsx(LoadingComponent, {}) });
var AddToCollection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalAddToCollection" */ "components/modal/body/add-to-collection"); }, { fallback: _jsx(LoadingComponent, {}) });
var SendAppToYourPhone = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalSendAppToYourPhone" */ "components/modal/body/send-app-to-your-phone"); }, { fallback: _jsx(LoadingComponent, {}) });
var EditProfilePicture = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalEditProfilePicture" */ "components/modal/body/edit-profile-picture"); }, { fallback: _jsx(LoadingComponent, {}) });
var AddPrivateCollection = loadable(function () { return import(/* webpackChunkName: "dynamic-ModalAddPrivateCollection" */ "components/modal/body/add-private-collection/AddPrivateCollection"); }, { fallback: _jsx(LoadingComponent, {}) }).AddPrivateCollection;
var PowerpointExport = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPowerpointExport" */ "components/modal/body/powerpoint-export"); }, { fallback: _jsx(LoadingComponent, {}) });
var Upload = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalUpload" */ "components/modal/body/upload"); }, { fallback: _jsx(LoadingComponent, {}) });
var FlyerFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFlyerFilters" */ "components/modal/body/flyer-filters"); }, { fallback: _jsx(LoadingComponent, {}) });
var StarAView = Loadable(function () { return import(/* webpackChunkName: "dynamic-StarAView" */ "components/modal/body/star-a-view"); }, { fallback: _jsx(LoadingComponent, {}) });
var StudioTips = Loadable(function () { return import(/* webpackChunkName: "dynamic-StudioTips" */ "components/modal/body/studio-tips"); }, { fallback: _jsx(LoadingComponent, {}) });
var Report = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalReport" */ "components/modal/body/report"); }, { fallback: _jsx(LoadingComponent, {}) });
var FailedUpload = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFailedUpload" */ "components/modal/body/failed-upload"); }, { fallback: _jsx(LoadingComponent, {}) });
var UpgradePrompt = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalUpgradePrompt" */ "components/modal/body/upgrade-prompt"); }, { fallback: _jsx(LoadingComponent, {}) });
var PlanogramFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPlanogramFilters" */ "components/modal/body/planogram-filters"); }, { fallback: _jsx(LoadingComponent, {}) });
var BigAnnouncement = Loadable(function () { return import(/* webpackChunkName: "dynamic-BigAnnouncement" */ "components/modal/body/big-announcement"); }, { fallback: _jsx(LoadingComponent, {}) });
var CreateCompany = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalCreateCompany" */ "components/modal/body/create-company"); }, { fallback: _jsx(LoadingComponent, {}) });
var SuperMario = Loadable(function () { return import(/* webpackChunkName: "dynamic-SuperMario" */ "components/modal/body/easter-egg-super-mario"); }, { fallback: _jsx(LoadingComponent, {}) });
var MapFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalMapFilters" */ "components/modal/body/map-filters"); }, { fallback: _jsx(LoadingComponent, {}) });
var ShareMenu = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalShareMenu" */ "components/modal/body/share-menu"); }, { fallback: _jsx(LoadingComponent, {}) });
var RemoveFromCollection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalRemoveFromCollection" */ "components/modal/body/remove-from-collection"); }, { fallback: _jsx(LoadingComponent, {}) });
var Share = loadable(function () { return import(/* webpackChunkName: "dynamic-ModalShare" */ "components/modal/body/share/Share"); }, { fallback: _jsx(LoadingComponent, {}) }).Share;
var LinkToPage = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLinkToPage" */ "components/modal/body/link-to-page"); }, { fallback: _jsx(LoadingComponent, {}) });
var HawkeyeInstructions = Loadable(function () { return import(/* webpackChunkName: "dynamic-HawkeyeInstructions" */ "components/modal/body/hawkeye-instructions"); }, { fallback: _jsx(LoadingComponent, {}) });
var DesignYourCards = Loadable(function () { return import(/* webpackChunkName: "dynamic-DesignYourCards" */ "components/modal/body/design-your-cards"); }, { fallback: _jsx(LoadingComponent, {}) });
var QuestionCode = loadable(function () { return import(/* webpackChunkName: "dynamic-QuestionCode" */ "components/question-code/QuestionCode"); }, { fallback: _jsx(LoadingComponent, {}) }).QuestionCode;
var DeleteQuestionCode = loadable(function () { return import(/* webpackChunkName: "dynamic-DeleteQuestionCode" */ "components/modal/body/delete-question-code/DeleteQuestionCode"); }, { fallback: _jsx(LoadingComponent, {}) }).DeleteQuestionCode;
var CreatePipelineCollection = loadable(function () { return import(/* webpackChunkName: "dynamic-CreatePipelineCollection" */ "components/modal/body/create-pipeline-collection/CreatePipelineCollection"); }, { fallback: _jsx(LoadingComponent, {}) }).CreatePipelineCollection;
var QuestionCodeFilters = loadable(function () { return import(/* webpackChunkName: "dynamic-QuestionCodeFilters" */ "components/modal/body/question-code-filters/QuestionCodeFilters"); }, { fallback: _jsx(LoadingComponent, {}) }).QuestionCodeFilters;
var AddDashboardCollection = loadable(function () { return import(/* webpackChunkName: "dynamic-AddDashboardCollection" */ "components/modal/body/add-dashboard-collection/AddDashboardCollection"); }, { fallback: _jsx(LoadingComponent, {}) }).AddDashboardCollection;
var InviteCollaborators = loadable(function () { return import(/* webpackChunkName: "dynamic-InviteCollaborators" */ "components/modal/body/invite-collaborators/InviteCollaborators"); }, { fallback: _jsx(LoadingComponent, {}) }).InviteCollaborators;
var ShareOfShelf = loadable(function () { return import(/* webpackChunkName: "dynamic-ShareOfShelf" */ "components/modal/body/share-of-shelf/ShareOfShelf"); }, { fallback: _jsx(LoadingComponent, {}) }).ShareOfShelf;
var PlanogramAnalytics = loadable(function () { return import(/* webpackChunkName: "dynamic-PlanogramAnalytics" */ "components/modal/body/planogram-analytics/PlanogramAnalytics"); }, { fallback: _jsx(LoadingComponent, {}) }).PlanogramAnalytics;
var FeedFilterSettings = loadable(function () { return import(/* webpackChunkName: "dynamic-FeedFilterSettings" */ "components/modal/body/feed-filter-settings/FeedFilterSettings"); }, { fallback: _jsx(LoadingComponent, {}) }).FeedFilterSettings;
export var MODAL_COMPONENTS = {
    "ADD_DASHBOARD_COLLECTION": AddDashboardCollection,
    "ADD_TO_COLLECTION": AddToCollection,
    "ANALYTICS_LEARN_MORE": AnalyticsLearnMore,
    "BIG_ANNOUNCEMENT": BigAnnouncement,
    "POWERPOINT_EXPORT": PowerpointExport,
    "COLLECTION_SHOW_COVERAGE_MODEL": CoverageModel,
    "CREATE_COMPANY": CreateCompany,
    "CREATE_COLLECTION": Collection,
    "CREATE_NEW_PC": AddPrivateCollection,
    "CREATE_PIPELINE_COLLECTION": CreatePipelineCollection,
    "DELETE": Delete,
    "DELETE_QUESTION_CODE": DeleteQuestionCode,
    "DESIGN_YOUR_CARDS": DesignYourCards,
    "EASTER_EGG_SHOW_SUPER_MARIO": SuperMario,
    "EDIT_PROFILE_PIC": EditProfilePicture,
    "EMAIL_DOMAIN": EmailDomain,
    "FAILED_UPLOAD": FailedUpload,
    "FEED_FILTERS": FeedFilters,
    "FEED_FILTER_SETTINGS": FeedFilterSettings,
    "FEED_LAYOUT_SETTINGS": FeedLayoutSettings,
    "FEED_SHARE_MENU": ShareMenu,
    "FEED_SORT_SETTINGS": FeedSort,
    "FILTER_MAP_BY_RETAILER": MapFilters,
    "FLYER_FILTERS": FlyerFilters,
    "GIVE_FEEDBACK": SendFeedback,
    "HAWKEYE_INSTRUCTIONS": HawkeyeInstructions,
    "INVITE_COLLABORATORS": InviteCollaborators,
    "LINK_TO_PAGE": LinkToPage,
    "HANDLE_INVITE_TEAM": InviteYourTeam,
    "KEYBOARD_SHORTCUTS": KeyboardShortcuts,
    "LOGIN": Login,
    "LOGIN_DISABLED": LoginDisabled,
    "NEW_QUESTION_CODE": QuestionCode,
    "PHOTO_RESPONSE_GROUP": PhotoResponseGroup,
    "PLANOGRAM_ANALYTICS": PlanogramAnalytics,
    "PLANOGRAM_FILTERS": PlanogramFilters,
    "PRICING_FILTER_PRICE": PriceFilter,
    "PRICING_FILTER_FEATURES": FeaturesFilter,
    "PRICING_FILTER_POSITION": PositionFilter,
    "PRICING_FILTER_DATE": DateFilter,
    "PRICING_FILTER_LOCATION": LocationFilter,
    "PRICING_FILTER_PRODUCT": ProductFilter,
    "QUESTION_CODE_FILTERS": QuestionCodeFilters,
    "REMOVE_FROM_COLLECTION": RemoveFromCollection,
    "REPORT": Report,
    "SEND_APP_TO_YOUR_PHONE": SendAppToYourPhone,
    "SHARE": Share,
    "SHARE_OF_SHELF": ShareOfShelf,
    "SHOW_VIEWER_BLOB": TextBlob,
    "STAR_A_VIEW": StarAView,
    "STARRED_VIEWS_INSTRUCTIONS": Arcade,
    "STUDIO_ADD_TEXT": StudioTextInput,
    "STUDIO_TIPS": StudioTips,
    "SUGGEST_MISSING_DATA": SuggestMissingData,
    "SWITCH_PRIVATE_SPACE": SwitchPrivateSpace,
    "TRENDS_INFO": TrendsLineInfo,
    "TRENDS_VISUALS": TrendsVisualInfo,
    "UPGRADE_FLASH": UpgradePrompt,
    "UPLOAD_PHOTOS": Upload,
    "WATCH_A_VIDEO": WatchAVideo,
    "WORD_CLOUD": WordCloud,
};
