var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from "@reduxjs/toolkit";
import { fetchInitialLoad } from "@redux/api/endpoints/search";
import { logout } from "@redux/actions/logout";
var initialState = {
    brands: [],
    categories: [],
    countries: [],
    channels: [],
    demographics: [],
    regions: [],
    retailers: [],
    tags: [],
    fetchedFromServer: false
};
var initialLoadSlice = createSlice({
    name: "initialLoad",
    initialState: initialState,
    reducers: { /* No reducers */},
    extraReducers: function (builder) {
        builder
            .addCase(fetchInitialLoad.fulfilled, function (_state, action) {
            return __assign(__assign({}, action.payload.data), { fetchedFromServer: action.payload.last_updated });
        })
            .addCase(logout, function () { return initialState; });
    }
});
export default initialLoadSlice.reducer;
