import { persistor, store, history } from "@redux/store";
import { PureComponent, lazy, Suspense } from "react";
import moment from "moment";
import ConnectedThemeProvider from "services/themes/index";
import ErrorBoundary from "services/error-handling/index";
import Loader from "components/loader/index";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ScrollToTop from "services/scroll-to-top/index";
import isUUID from "validator/lib/isUUID";
import { HistoryRouter } from "redux-first-history/rr6";
import { isMobileDevice } from "constants/variables/index";
import PageViewTracker from "services/hubspot/page-views/index"; // Import the tracker

const LoginRouter = lazy(() =>
  import(
    /* webpackChunkName: "dynamic-LoginRouter" */ "services/login-router/index"
  )
);

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }
  componentDidMount() {
    this.setState({ mounted: true });
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    if (isMobileDevice) {
      window.scrollTo(0, 1);
    }
    const hash = window.location.hash;
    if (hash && isUUID(hash.substring(1))) {
      if (
        window.location.pathname.includes("/flyer") ||
        window.location.pathname.includes("/circulars") ||
        window.location.pathname.includes("/circular") ||
        window.location.pathname.includes("/flyers") ||
        window.location.pathname.includes("/f/")
      ) {
        window.location.href = `/f/${hash.substring(1)}`;
      } else {
        window.location.href = `/s/${hash.substring(1)}`;
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }
  handleVisibilityChange() {
    if (
      document.visibilityState === "visible" &&
      this.props.serverData &&
      this.props.serverData.timestamp
    ) {
      if (
        moment
          .duration(
            moment(new Date()).diff(
              moment.unix(this.props.serverData.timestamp / 1000)
            )
          )
          .asHours() > 120
      ) {
        window.location.reload();
      }
    }
  }
  render() {
    if (!this.state.mounted) {
      return <Loader />;
    }
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate
            loading={<Loader size="2em" text="Launching Shelfgram..." />}
            persistor={persistor}
          >
            <HistoryRouter history={history}>
              <PageViewTracker />
              <ScrollToTop>
                <ConnectedThemeProvider>
                  <Suspense fallback={<Loader />}>
                    <LoginRouter serverData={this.props.serverData} />
                  </Suspense>
                </ConnectedThemeProvider>
              </ScrollToTop>
            </HistoryRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
