var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { logout } from "@redux/actions/logout";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isOwnedCollection, isCollaborativeCollection, } from "shared/types/collection";
import { fetchUserCollections, updateCollectionCollaborators } from "@redux/api/endpoints/collections";
var initialState = {
    collections: [],
    fetchedFromServer: false
};
var UserCollectionsSlice = createSlice({
    name: 'userCollections',
    initialState: initialState,
    reducers: {
        userCollectionsReset: function () { return initialState; },
        photoUnlinkedFromCollection: function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.payload.collection_id; });
            if (collection) {
                collection.photo_count = Math.max(0, collection.photo_count - 1);
            }
        },
        photosLinkedToCollections: function (state, action) {
            var linkedCollectionIds = action.payload.collections.map(function (collection) { return collection.id; });
            state.collections
                .filter(function (collection) { return linkedCollectionIds.includes(collection.id); })
                .forEach(function (collection) { collection.photo_count += action.payload.photos.length; });
        },
        collectionCollaboratorsAdded: function (state, action) {
            var _a;
            var collection = state.collections.find(function (collection) { return collection.id === action.payload.collectionId; });
            if (collection) {
                var newCollaborators = action.payload.collaborators.map(function (_a) {
                    var id = _a.id, name = _a.name, img = _a.img;
                    return { id: id, name: name, img: img, is_admin: false };
                });
                (_a = collection.collaborators).push.apply(_a, newCollaborators);
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchUserCollections.fulfilled, function (state, action) {
            return __assign(__assign({}, action.payload), { fetchedFromServer: true });
        })
            .addCase(updateCollectionCollaborators.fulfilled, function (state, action) {
            var body = action.meta.arg.body;
            if (body.action === "remove") {
                var updatedCollection_1 = state.collections.find(function (collection) {
                    return collection.id === body.collection_id;
                });
                if (updatedCollection_1) {
                    if (updatedCollection_1.is_admin) {
                        updatedCollection_1.collaborators = updatedCollection_1.collaborators.filter(function (collaborator) {
                            return !body.member_ids.includes(collaborator.id);
                        });
                    }
                    else {
                        state.collections = state.collections.filter(function (collection) {
                            return collection.id !== updatedCollection_1.id;
                        });
                    }
                }
            }
        })
            .addCase("COLLECTION_CREATE_DATA", function (state, action) {
            state.collections.push(action.payload);
        })
            .addCase("CHANGE_LANDING_PAGE_PROFILE_PIC", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
            if (collection) {
                collection.source = action.data.source;
                collection.img = action.data.img;
            }
        })
            .addCase("CHANGE_LANDING_PAGE_COVER", function (state, action) {
            if (!action.data.cover.startsWith("blob:")) {
                var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
                if (collection) {
                    collection.cover_url = action.data.cover;
                }
            }
        })
            .addCase("COLLECTION_UPLOAD_COVER_DATA", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.meta.body.landing_page_slug; });
            if (collection) {
                collection.background = action.payload.background;
                collection.cover_url = null;
            }
        })
            .addCase("CHANGE_LANDING_PAGE_NAME", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
            if (collection) {
                collection.name = action.data.name;
            }
        })
            .addCase(logout, function () { return initialState; });
    },
    selectors: {
        selectUserCollections: function (sliceState) { return sliceState.collections; }
    }
});
export var userCollectionsReset = (_a = UserCollectionsSlice.actions, _a.userCollectionsReset), photoUnlinkedFromCollection = _a.photoUnlinkedFromCollection, photosLinkedToCollections = _a.photosLinkedToCollections, collectionCollaboratorsAdded = _a.collectionCollaboratorsAdded;
export var selectUserCollections = UserCollectionsSlice.selectors.selectUserCollections;
export var selectUserOwnedCollections = createSelector([selectUserCollections], function (userCollections) { return userCollections.filter(isOwnedCollection); });
export var selectCollaborativeCollections = createSelector([selectUserCollections], function (userCollections) { return userCollections.filter(isCollaborativeCollection); });
export var selectUserCollectionById = createSelector([selectUserCollections, function (_state, collectionId) { return collectionId; }], function (userCollections, collectionId) {
    return userCollections.find(function (collection) {
        return collection.id === collectionId;
    });
});
export default UserCollectionsSlice.reducer;
