import { createApiThunk } from "@redux/api/createApiThunk";
export var fetchCuratedCollections = createApiThunk({
    route: "collection/curated_collections",
    component: "CURATED_COLLECTIONS",
    method: "GET"
});
export var createPipelineCollection = createApiThunk({
    route: "collection/retail_pipelines/create",
    component: "CREATE_PIPELINE_COLLECTION",
    method: "POST"
});
export var fetchUserCollections = createApiThunk({
    route: "collection/owned",
    component: "USER_COLLECTIONS",
    method: "GET"
});
export var updateCollectionCollaborators = createApiThunk({
    method: "POST",
    route: "collection/collaboration",
    component: "COLLECTION_COLLABORATORS",
    refetchThunk: fetchUserCollections,
    loadingToast: "Updating collaborators..."
});
export var checkAccessToCollection = createApiThunk({
    route: "collection/id/:collectionId/access",
    component: "CHECK_ACCESS_TO_COLLECTION",
    method: "POST",
});
