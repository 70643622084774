import { createSelector } from "@reduxjs/toolkit";
import { emptyArray } from "constants/variables";
export var FeatureGateIds;
(function (FeatureGateIds) {
    FeatureGateIds[FeatureGateIds["Analytics"] = 1] = "Analytics";
    FeatureGateIds[FeatureGateIds["Circulars"] = 2] = "Circulars";
    FeatureGateIds[FeatureGateIds["Heatmaps"] = 3] = "Heatmaps";
    FeatureGateIds[FeatureGateIds["PriceChecks"] = 4] = "PriceChecks";
    FeatureGateIds[FeatureGateIds["Campaigns"] = 5] = "Campaigns";
    FeatureGateIds[FeatureGateIds["Stories"] = 6] = "Stories";
})(FeatureGateIds || (FeatureGateIds = {}));
var selectFeatureGates = function (state) { var _a, _b; return ((_b = (_a = state.userPreferences) === null || _a === void 0 ? void 0 : _a.current_pc) === null || _b === void 0 ? void 0 : _b.feature_gates) || emptyArray; };
var selectFeatureGateMap = createSelector([selectFeatureGates], function (featureGates) {
    if (!featureGates)
        return {};
    return featureGates.reduce(function (acc, gate) {
        var _a;
        acc[gate.id] = (_a = gate.is_enabled) !== null && _a !== void 0 ? _a : true;
        return acc;
    }, {});
});
var createFeatureGateSelector = function (gateId) { return createSelector([selectFeatureGateMap], function (gateMap) {
    var _a;
    return ({
        is_enabled: Object.keys(gateMap).length === 0 ? true : ((_a = gateMap[gateId]) !== null && _a !== void 0 ? _a : true)
    });
}); };
export var selectAnalyticsFeatureGate = createFeatureGateSelector(FeatureGateIds.Analytics);
export var selectCircularsFeatureGate = createFeatureGateSelector(FeatureGateIds.Circulars);
export var selectHeatmapsFeatureGate = createFeatureGateSelector(FeatureGateIds.Heatmaps);
export var selectPriceChecksFeatureGate = createFeatureGateSelector(FeatureGateIds.PriceChecks);
export var selectCampaignsFeatureGate = createFeatureGateSelector(FeatureGateIds.Campaigns);
export var selectStoriesFeatureGate = createFeatureGateSelector(FeatureGateIds.Stories);
