import { createApiThunk } from "@redux/api/createApiThunk";
export var invalidateCacheKeyByPattern = createApiThunk({
    route: "admin/invalidate_cache",
    component: "INVALIDATE_CACHE_KEYS",
    method: "POST"
});
export var invalidateCacheKeyByExactMatch = createApiThunk({
    route: "admin/invalidate_cache_by_match",
    component: "INVALIDATE_CACHE_KEYS",
    method: "POST"
});
export var enterUserImpersonation = createApiThunk({
    route: "admin/impersonate/:memberId",
    component: "ADMIN_IMPERSONATE_USER",
    method: "POST"
});
export var exitUserImpersonation = createApiThunk({
    route: "admin/unimpersonate",
    component: "ADMIN_UNIMPERSONATE_USER",
    method: "POST"
});
export var invalidateUser = createApiThunk({
    route: "admin/invalidate_user/:memberSlug",
    component: "ADMIN_LOGOUT_A_USER",
    method: "POST"
});
export var toggleBetaFeatures = createApiThunk({
    route: "admin/beta_features",
    component: "TOGGLE_BETA_FEATURES",
    method: "POST"
});
export var fetchSyndicatedCollections = createApiThunk({
    route: "admin/syndicated_collections",
    component: "SYNDICATED_COLLECTIONS",
    method: "GET"
});
export var updateSyndicatedCollectionPromotion = createApiThunk({
    method: "POST",
    component: "SYNDICATED_COLLECTION_PROMOTION_CHANGE",
    route: "admin/syndicated_collections/promoted/update",
    loadingToast: "Updating promotion status...",
    successToast: "Promotion status updated!",
});
export var fetchQuestionCodes = createApiThunk({
    route: "admin/question_codes",
    component: "QUESTION_CODES",
    method: "GET"
});
export var fetchQuestionCodeMetadata = createApiThunk({
    route: "admin/question_codes/metadata",
    component: "QUESTION_CODE_METADATA",
    method: "GET"
});
export var createOrUpdateQuestionCode = createApiThunk({
    route: "admin/question_code/update",
    component: "UPDATE_QUESTION_CODE",
    method: "POST",
    takeLatest: true,
});
export var deleteQuestionCode = createApiThunk({
    route: "admin/question_code/delete",
    component: "DELETE_QUESTION_CODE",
    method: "POST"
});
export var requestCompanyMerge = createApiThunk({
    route: "admin/company_merge",
    component: "MERGE_COMPANIES",
    method: "POST"
});
