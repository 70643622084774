import "react-datepicker/dist/react-datepicker.css";

import {
  FaChevronRight,
  FaRegCalendarAlt,
  FaMinusCircle,
} from "react-icons/fa";
import {
  adminPlanId,
  awsPhotosPrefix,
  MATCH_OPTIONS,
  SUGGESTION_WHITELIST,
  isOptions,
  timeTravelOptions,
  osaOptions,
  isOptionsNumeric,
  isOptionAdmin,
  asyncSelectors,
  tooltipDelayLong,
  dateOptions,
  matchMinWidthMap,
  minPhotosDate,
  photoTagTypes,
  pluralToSingular,
  monthsOptions,
  useCompaniesS3Key,
  emptyArray,
  isNoneOptionSelectors,
  isAllOptionSelectors,
  isAllOption,
} from "constants/variables";
import {
  OSAGoodIcon,
  OSAOkayIcon,
  OSAPoorIcon
} from "components/analytics-reports/reports/on-shelf-availability/osa_legend_icons";
import Tooltip from "components/tooltip/index";
import React, { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import MiniMap from "./map/index";
import DatePicker from "react-datepicker";
import { DebounceInput } from "react-debounce-input";
import { FallbackUserImage } from "components/fallback-user-image/FallbackUserImage";
import { Option, TooltipText } from "constants/styled-components";
import Selector from "components/selector/index";
import { connect } from "react-redux";
import { getImageSource } from "shared/helpers/image";
import { asArray } from "shared/helpers/asArray";
import { selectCuratedCollectionsByType } from "@redux/slices/curatedCollectionsSlice";
import { handleKeyboardClick } from "shared/helpers/handleKeyboardClick";
import { selectShowAdvancedFilters } from "@redux/slices/feedFilterRulesSlice";
import { fetchSuggestionsForLandingPage } from "@redux/api/endpoints/suggestions";
import { selectIsFetchingByPrefix } from "@redux/slices/apiCallsSlice";

export const FILTER_RULES_FULL_WIDTH_BREAKPOINT = 640;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div`
  animation: fadeIn 0.5s;
  transition: background 0.3s ease, padding 0.3s ease;
  background: ${(props) =>
    props.advanced
      ? "transparent"
      : props.pending
        ? props.theme.grey6
        : props.theme.pastelBlue};
  width: ${(props) => (props.center ? "auto" : "100%")};
  padding: ${(props) =>
    props.pending
      ? props.advanced
        ? "1em 1em 1em 1.5em" 
        : "1em 2em"
      : props.advanced
        ? "0.75em 1em 0.75em 1.5em"
        : "0.75em 2em"
  };

  .react-select__indicators {
    opacity: ${(props) => (props.pending ? "1" : "0.5")};
  }

  .react-select__control {
    box-shadow: ${(props) =>
      props.pending ? "0px 1px 5px rgba(0, 0, 0, 0.1)" : "none"};
  }

  @media (max-width: ${FILTER_RULES_FULL_WIDTH_BREAKPOINT}px) {
    justify-content: center;
    flex-direction: column-reverse;
    padding-right: 2em;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width || undefined};
  flex-wrap: ${props => props.flexWrap || undefined};

  @media (max-width: ${FILTER_RULES_FULL_WIDTH_BREAKPOINT}px) {
    flex-wrap: wrap !important;
    justify-content: flex-start;
  }
`;
const CloseContainer = styled.span`
  padding: 0.5em;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    padding-right: 0.5em;
  }
  svg,
  span {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.midRed};
    color: ${(props) => props.theme.midRed};
    opacity: ${(props) => (props.pending ? "0.9" : "0.3")};
  }
  &:hover svg {
    transform: translateY(-2px);
    opacity: 0.9;
  }
`;
const H3 = styled.h3`
  color: ${(props) => props.theme.grey2};
  margin: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: bold;
  padding: 0 0.35em;
  margin-right: 0.5em;
  display: flex;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? "center" : "left")};
  align-items: center;
  justify-content: center;

  @media (max-width: ${FILTER_RULES_FULL_WIDTH_BREAKPOINT}px) {
    padding: 0.5em;
  };
`;

const textOptions = ["text", "postal_zip"];
const textSelectorOptions = [
  {
    id: "is",
    value: "includes",
    label: "includes",
    noImg: true,
  },
  {
    id: "is_not",
    value: "excludes",
    label: "excludes",
    noImg: true,
  },
];
const presetOsaOptions = {
  good: (
    <Option>
      <OSAGoodIcon/>
      <H3>{`Good (over 90% in stock)`}</H3>
    </Option>
  ),
  okay: (
    <Option>
      <OSAOkayIcon/>
      <H3>{`Okay (70% - 89% in stock)`}</H3>
    </Option>
  ),
  poor: (
    <Option>
      <OSAPoorIcon/>
      <H3>{`Poor ( <70% in stock )`}</H3>
    </Option>
  ),
};

class Condition extends PureComponent {
  constructor(props) {
    super(props);
    this.fullWidthMediaQuery = window.matchMedia(`(max-width: ${FILTER_RULES_FULL_WIDTH_BREAKPOINT}px)`);
    this.state = {
      fullWidth: this.fullWidthMediaQuery.matches
    };
    this.setSelectorMatch = this.setSelectorMatch.bind(this);
    this.setSelector = this.setSelector.bind(this);
    this.setSelectorIs = this.setSelectorIs.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.updateScreenType = this.updateScreenType.bind(this);
  }

  componentDidMount() {
    this.fullWidthMediaQuery.addEventListener("change", this.updateScreenType);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.condition.match && this.props.condition.match === "text") {
      this.props.apiCall({
        method: "POST",
        component: "FEATURE_FOUND_TEXT_SEARCH",
        route: "user/feature_found",
        body: {
          user_id: this.props.dataUser.id,
          feature_id: 7,
        },
      });
    }
  }

  componentWillUnmount() {
    this.fullWidthMediaQuery.removeEventListener("change", this.updateScreenType);
  }

  updateScreenType() {
    this.setState({ fullWidth: this.fullWidthMediaQuery.matches });
  }

  onSearch(input) {
    this.setSelector({
      index: {
        ruleIndex: this.props.ruleIndex,
        conditionIndex: this.props.conditionIndex,
      },
      data: [{ id: input.target.value }],
    });
  }

  setSelectorMatch(data) {
    if (data.data.value === "map") {
      this.props.setData({
        type: "SHOW_FULLSCREEN_MODAL",
        data: {
          is: this.props.condition.is === "is",
          ruleIndex: data.index.ruleIndex,
          conditionIndex: data.index.conditionIndex,
        },
      });
    }
    this.props.setData({
      type: "FILTER_CONDITION_CHANGE_MATCH",
      data: {
        value: data.data.value,
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
      },
    });
    if (
      data.data.suggest &&
      !this.props.isFetchingSuggestions &&
      this.props.dataLandingPageAbout?.id &&
      this.props.dataLandingPageAbout.route &&
      this.props.dataLandingPageAbout.route !== "collection" &&
      !this.props.feedSuggestionsLandingPage[data.data.value]
    ) {
      const { is_retailer, is_brand, route, url_slug, id } = this.props.dataLandingPageAbout;
      this.props.dispatch(
        fetchSuggestionsForLandingPage({
          routeParams: {
            suggestionType: data.data.value,
            lpType: is_retailer ? "retailer" : is_brand ? "brand" : route,
            lpId: url_slug || id
          },
          componentSuffix: data.data.value,
        })
      );
    }
  }

  setSelectorIs(data) {
    this.props.setData({
      type: "FILTER_CONDITION_CHANGE_LOGIC",
      data: {
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
        value: data.data.id,
      },
    });
  }

  setSelector(data) {
    const selectedItems = data.data
      ? asArray(data.data)
        .map(item => ({
          id: item.url_slug || item.id,
          img: item.img,
          noImg: item.noImg,
          name: item.value,
          source: useCompaniesS3Key.includes(item.source || item.type)
            ? "companies"
            : item.source || item.type
        }))
      : emptyArray;
    if (
      selectedItems.length &&
      SUGGESTION_WHITELIST.includes(this.props.condition.match)
    ) {
      this.props.setData({
        type: "ADD_LOCAL_SUGGESTION",
        data: {
          suggestionType: this.props.condition.match,
          item: selectedItems.at(-1)
        }
      });
    }
    this.props.setData({
      type: "FILTER_CONDITION_CHANGE_ITEM",
      data: {
        ruleIndex: data.index.ruleIndex,
        conditionIndex: data.index.conditionIndex,
        item: selectedItems
      },
    });
  }

  removeCondition() {
    this.props.removeCondition(this.props.ruleIndex, this.props.conditionIndex);
  }

  setStartDate(date) {
    this.props.setStartDate(
      this.props.ruleIndex,
      this.props.conditionIndex,
      date
    );
  }

  setEndDate(date) {
    this.props.setEndDate(
      this.props.ruleIndex,
      this.props.conditionIndex,
      date
    );
  }

  prepareSelectorOptions(options, sorted) {
    return (
      sorted ? options.slice().sort((a, b) => (a.name > b.name ? 1 : -1)) : options
    ).map((item) => ({
      type: this.props.condition.match,
      value: item.name || item.label,
      source: item.source,
      name: item.name,
      img: item.img,
      id: item.id,
      label: (
        <Option>
          <img
            src={getImageSource({ ...item, type: this.props.condition.match })}
            alt={item.name}
          />
          {item.name || item.label}
        </Option>
      ),
    }));
  }

  disabledOption(value) {
    const { existingOptions } = this.props;
    return (
      existingOptions.includes("cities") && ["state", "country", "region"].includes(value) ||
      existingOptions.includes("states") && ["country", "region"].includes(value) ||
      existingOptions.includes("countries") && value === "region"
    )
  }

  renderIsOptions(isSingle) {
    const { condition: { match }, dataUser } = this.props;
    if (match === "osa") return isOptionsNumeric;
    const options = [...isOptions(isSingle)];

    if (isAllOptionSelectors.includes(match)) {
      options.splice(1, 0, isAllOption)
    }
    if (dataUser?.plan_id === adminPlanId && isNoneOptionSelectors.includes(match)) {
      options.push(isOptionAdmin)
    }
    return options;
  }

  renderMatchOptions() {
    const { condition, dataUser, existingOptions, dataLandingPageAbout } = this.props;
    const isPrivateSpace = !!dataLandingPageAbout?.collection_users;
    const isPremiumUser = dataUser?.plan_id > 1;

    const processOption = ({ value, label, suggest, plural }) => ({
      label,
      suggest,
      value: plural,
      disabled: this.disabledOption(value),
      excluded: (
        condition.match === plural ||
        existingOptions?.includes(plural) ||
        value === "user" && !isPremiumUser ||
        value === "location" && (isPrivateSpace || !existingOptions.includes("retailers"))
      )
    });

    const filteredOptions = MATCH_OPTIONS.map((item) => ({
      label: item.group,
      options: item.options.map(processOption).filter(option => !option.excluded)
    }));

    if (dataLandingPageAbout?.show_osa_filters) {
      filteredOptions.push({
        label: "Stock Levels",
        suggest: false,
        value: "osa",
      });
    }
    return filteredOptions;
  }

  renderSelectOptions() {
    const {
      condition,
      dataLandingPageAbout,
      feedSuggestionsLandingPage,
      feedSuggestionsMain,
      dataUser,
    } = this.props;

    const predefinedOptions = {
      dates: dateOptions,
      time_travel: timeTravelOptions,
      months: monthsOptions,
      osa: osaOptions,
    }
    if (condition.match in predefinedOptions) {
      return predefinedOptions[condition.match];
    }
    if (condition.match === "users") {
      return [
        {
          label: "suggestions",
          options: [
            {
              type: "users",
              value: "Me",
              noImg: true,
              id: dataUser.id,
              label: "Me",
            },
            {
              type: "users",
              value: "Anyone on my team",
              noImg: true,
              id: "anyone",
              label: "Anyone on my team",
            },
          ],
        },
      ].concat(
        dataUser.team?.map(({ img, id, first_name, last_name }) => ({
          type: "users",
          value: `${first_name} ${last_name}`,
          img,
          id,
          label: (
            <Option>
              <FallbackUserImage
                src={img}
                firstName={first_name}
                lastName={last_name}
              />
              {`${first_name} ${last_name}`}
            </Option>
          ),
        })) ?? []
      )
    }
    // For all other matches, only provide suggestions;
    // Selector will add remaining options from initialLoad in `getOptionsToUse()` if match isn't async
    if (condition.match === "tags") {
      return [
        {
          label: "suggestions",
          options: this.prepareSelectorOptions(
            photoTagTypes.map(({ name, img, id }) => ({
              img, source: "tags",
              name, label: name,
              id, value: id,
            })),
            false
          ),
        }
      ];
    }
    const suggestions =
      dataLandingPageAbout?.id
        ? feedSuggestionsLandingPage[condition.match]
        : feedSuggestionsMain[condition.match];

    if (suggestions?.length) {
      const hydratedSuggestions = condition.match === "collections"
        ? suggestions.map(suggestion => ({
          ...suggestion,
          name:
            this.props.retailPipelines.find(pipeline =>
              pipeline.id === suggestion.id
            )?.label || suggestion.name
        }))
        : suggestions;
      return [
        {
          label: "suggestions",
          options: this.prepareSelectorOptions(hydratedSuggestions, false),
        },
      ];
    }
    return emptyArray;
  }

  render() {
    const { fullWidth } = this.state;
    const { condition, ruleIndex, conditionIndex, showAdvancedFilters, dataUser, dataLandingPageAbout, pageId, theme } = this.props;

    const isSingle = ["users", "dates", "time_travel", "question_id", "osa"].includes(condition.match);
    const conditionIsOptions = this.renderIsOptions(isSingle);
    const pending = textOptions.includes(condition.match)
      ? !(condition.item?.[0]?.id?.length >= 3)
      : !(condition.item?.length > 0);

    return (
      <Wrapper
        key={`condition-${conditionIndex}-${condition.id}`}
        advanced={showAdvancedFilters}
        pending={pending}
      >
        <InnerWrapper key={`inner-${condition.match}-${condition.id}`}>
          {condition.match === "related" ? (
            <Flex>
              <H3>Photo is similar to</H3>
              <FallbackUserImage
                src={`${awsPhotosPrefix}compressed/${condition.item[0].id}-M`}
                width="3em"
                height="3em"
              />
            </Flex>
          ) : ["saved", "uploaded"].includes(condition.match) ? (
            <Flex>
              <FallbackUserImage
                src={dataUser.photo}
                width="3em"
                height="3em"
              />
              <H3>{`${dataUser.first_name} ${condition.match} the photo`}</H3>
            </Flex>
          ) : condition.disabled && ["products", "cities"].includes(condition.match) ? (
            <H3 textTransform="">
              {`${pluralToSingular[condition.match].upper} is ${condition.item[0].name}`}
            </H3>
          ) : (
            <Flex width="100%" flexWrap={condition.match === "dates" ? "wrap" : "nowrap"}>
              {conditionIndex !== 0 && (
                <H3>AND</H3>
              )}
              <Selector
                isSingle
                portal
                maxWidth={fullWidth ? "100%" : "12em"}
                minWidth={
                  fullWidth
                    ? "100%"
                    : matchMinWidthMap[condition.match] || "11em"
                }
                defaultValue={
                  condition.match === "companies" &&
                  dataLandingPageAbout?.is_brand &&
                  !dataLandingPageAbout.is_retailer
                    ? [
                      {
                        label: "Brand",
                        value: condition.match,
                        noImg: true,
                        id: condition.match,
                      },
                    ]
                    : condition.match === "companies" &&
                    dataLandingPageAbout?.is_retailer &&
                    !dataLandingPageAbout.is_brand
                      ? [
                        {
                          label: "Retailer",
                          value: condition.match,
                          noImg: true,
                          id: condition.match,
                        },
                      ]
                      : pluralToSingular[condition.match]
                        ? [
                          {
                            label: pluralToSingular[condition.match].upper,
                            value: condition.match,
                            noImg: true,
                            id: condition.match,
                          },
                        ]
                        : null
                }
                autoFocus={!condition.match}
                options={this.renderMatchOptions()}
                filtered
                isDisabled={
                  condition.disabled ||
                  ["question_id", "private_collections"].includes(condition.match)
                }
                index={{
                  ruleIndex,
                  conditionIndex
                }}
                clickAction={this.setSelectorMatch}
              />
              {condition.match && ![...textOptions, "map"].includes(condition.match) && (
                condition.match === "dates" ? (
                  <H3>is</H3>
                ) : condition.match === "osa" && condition.item?.[0]?.id in presetOsaOptions ? (
                  <H3>are</H3>
                ) : (
                  <Selector
                    key={`is-selector-${condition.match}-${condition.is}-${condition.id}`}
                    isSingle
                    maxWidth={fullWidth ? "100%" : "11em"}
                    minWidth={
                      fullWidth
                        ? "100%"
                        : condition.match === "osa"
                          ? "9em"
                          : "8em"
                    }
                    portal
                    isSearchable={false}
                    defaultValue={conditionIsOptions.filter(option =>
                      option.id === condition.is
                    )}
                    isDisabled={
                      condition.disabled ||
                      ["question_id", "products", "locations", "private_collections"].includes(condition.match)
                    }
                    index={{
                      ruleIndex,
                      conditionIndex
                    }}
                    clickAction={this.setSelectorIs}
                    options={conditionIsOptions}
                    hideSelectedOptions
                  />
                )
              )}
              {fullWidth && (
                <Flex
                  tabIndex={0}
                  onClick={this.removeCondition}
                  onKeyDown={e => handleKeyboardClick(e, this.removeCondition)}
                >
                  <CloseContainer pending={pending}>
                    <FaMinusCircle/>
                  </CloseContainer>
                </Flex>
              )}
              <span
                className={
                  textOptions.includes(condition.match)
                    ? "textFilterContainer"
                    : condition.match === "map"
                      ? "mapFilterContainer"
                      : null
                }
              >
                {condition.match && condition.is !== "is_none" && (
                  textOptions.includes(condition.match) ? (
                    <>
                      <Selector
                        isSingle
                        maxWidth={fullWidth ? "100%" : "12em"}
                        minWidth={fullWidth ? "100%" : "11em"}
                        portal
                        index={{
                          ruleIndex,
                          conditionIndex
                        }}
                        defaultValue={textSelectorOptions.filter(option =>
                          option.id === condition.is
                        )}
                        clickAction={this.setSelectorIs}
                        isSearchable={false}
                        options={textSelectorOptions.filter(option =>
                          option.id !== condition.is
                        )}
                      />
                      <DebounceInput
                        type="text"
                        id="textFilter"
                        name="query"
                        value={condition.item?.[0]?.id || ""}
                        placeholder={
                          condition.match === "text"
                            ? "Use double quotes to find exact phrases"
                            : "Filter locations by post code..."
                        }
                        autoComplete="off"
                        autoFocus
                        debounceTimeout={400}
                        onChange={this.onSearch}
                        style={{
                          width: "100%",
                          textTransform:
                            condition.match === "postal_zip"
                              ? "uppercase"
                              : "none",
                          boxSizing: "border-box",
                          borderRadius: "4px",
                          marginLeft: "0.25em",
                          padding: "0.75em",
                          outline: "0 !important",
                          backgroundColor: theme.white,
                          color: theme.grey1,
                          border: `1px solid ${theme.grey4}`,
                        }}
                      />
                    </>
                  ) : condition.match === "map" ? (
                    condition.item?.[0] && (
                      <>
                        <H3>{
                          condition.is === "is_not"
                            ? "excludes"
                            : "includes"
                        }</H3>
                        <MiniMap
                          is={condition.is}
                          data={condition.item[0]}
                          conditionIndex={conditionIndex}
                          ruleIndex={ruleIndex}
                        />
                      </>
                    )
                  ) : condition.match === "osa" && condition.item?.[0]?.id in presetOsaOptions ? (
                    presetOsaOptions[condition.item[0].id]
                  ) : (
                    <Selector
                      key={`selector-${condition.match}-${ruleIndex}-${conditionIndex}-pageId-${!!pageId}`}
                      portal
                      async={asyncSelectors.includes(condition.match)}
                      showSelectorType={asyncSelectors.includes(condition.match)}
                      cacheOptions={condition.match === "retailers"}
                      selectorType={condition.match}
                      isSingle={isSingle}
                      filtered
                      index={{
                        ruleIndex,
                        conditionIndex
                      }}
                      maxWidth={
                        fullWidth
                          ? "100%"
                          : showAdvancedFilters ? "28em" : "30em"
                      }
                      minWidth="22em"
                      isDisabled={
                        condition.match === "question_id" ||
                        condition.match === "products" ||
                        (condition.match === "locations" && (
                          dataLandingPageAbout?.route === "location" ||
                          !pageId
                        )) ||
                        (condition.match === "retailers" && dataLandingPageAbout?.route === "location") ||
                        condition.match === "private_collections" ||
                        condition.disabled
                      }
                      component="FILTER_RULES"
                      clickAction={this.setSelector}
                      placeholder={`${isSingle ? "Select" : "Search for"} ${
                        condition.match === "time_travel"
                          ? ""
                          : condition.match === "osa"
                            ? "stock level"
                            : condition.match + "..."
                      }`}
                      disabledPlaceholder={condition.match === "locations" && "Select a retailer..."}
                      pageId={pageId}
                      autoFocus={!condition.item?.length}
                      defaultValue={
                        condition.match === "time_travel"
                          ? timeTravelOptions.filter((x) => x.id === condition.item?.[0]?.id)
                          : condition.item?.map((item) => ({
                            type: condition.match,
                            value: item.name || item.label || item.value,
                            source: item.source,
                            noImg:
                              item.noImg ||
                              ["dates", "osa"].includes(condition.match),
                            img: item.img,
                            id: item.id,
                            label:
                              condition.match === "dates" && item.id === "custom" ? (
                                <Option>
                                  <FaRegCalendarAlt/>
                                  Custom
                                </Option>
                              ) : (item.noImg || condition.match === "dates") && (
                                <Option>
                                  {item.name || item.label || item.value}
                                </Option>
                              )
                          })
                        ) ?? emptyArray
                      }
                      options={this.renderSelectOptions()}
                    />
                  )
                )}
              </span>
              {condition.match === "dates" && condition.item?.[0]?.id === "custom" && (
                <>
                  <DatePicker
                    disabledKeyboardNavigation
                    placeholderText="Start date"
                    selected={condition.startDate}
                    onChange={this.setStartDate}
                    selectsStart
                    withPortal
                    portalId="date-picker-portal"
                    minDate={minPhotosDate}
                    maxDate={new Date()}
                    showYearDropdown
                    startDate={condition.startDate}
                    endDate={condition.endDate}
                    dateFormat="MMMM d, yyyy"
                  />
                  <FaChevronRight color={theme.grey2}/>
                  <DatePicker
                    disabledKeyboardNavigation
                    placeholderText="End date"
                    selected={condition.endDate}
                    onChange={this.setEndDate}
                    selectsEnd
                    withPortal
                    portalId="date-picker-portal"
                    showYearDropdown
                    dateFormat="MMMM d, yyyy"
                    maxDate={new Date()}
                    startDate={condition.startDate}
                    endDate={condition.endDate}
                    minDate={condition.startDate || minPhotosDate}
                  />
                </>
              )}
            </Flex>
          )}
          {!fullWidth && (
            <Flex>
              {!condition.disabled && (
                <Tooltip
                  unmountHTMLWhenHide
                  delay={tooltipDelayLong}
                  html={<TooltipText maxWidth="800px">Remove condition</TooltipText>}
                  position="top"
                >
                  <CloseContainer
                    onKeyDown={e => handleKeyboardClick(e, this.removeCondition)}
                    onClick={this.removeCondition}
                    pending={pending}
                    tabIndex={0}
                  >
                    <FaMinusCircle/>
                  </CloseContainer>
                </Tooltip>
              )}
            </Flex>
          )}
        </InnerWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  feedSuggestionsLandingPage: state.feedSuggestionsLandingPage,
  feedSuggestionsMain: state.feedSuggestionsMain,
  dataLandingPageAbout: state.dataLandingPageAbout,
  retailPipelines: selectCuratedCollectionsByType(state, "retail_pipelines"),
  isFetchingSuggestions: selectIsFetchingByPrefix(state, "FEED_FILTERS_GET_SUGGESTIONS"),
  showAdvancedFilters: selectShowAdvancedFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  dispatch
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Condition)
);
