var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var BaseSVGIcon = function (_a) {
    var path = _a.path, color = _a.color, props = __rest(_a, ["path", "color"]);
    return (_jsx("svg", __assign({ fill: color }, props, { viewBox: '0 0 100 46', width: '100px', height: 'auto', children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: path }) })));
};
var OSAGoodIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#01E09E' : _b, props = __rest(_a, ["color"]);
    return (_jsx(BaseSVGIcon, __assign({ color: color, path: 'M4 0C1.79102 0 0 1.79077 0 4V42C0 44.2092 1.79102 46 4 46H96C98.209 46 100 44.2092 100 42V4C100 1.79077 98.209 0 96 0H4ZM4 4H8.87891V13.0605H4V4ZM4 18.6362H8.87891V27.6968H4V18.6362ZM8.87891 32.5757H4V41.6362H8.87891V32.5757ZM15.1519 18.6362H20.0308V27.6968H15.1519V18.6362ZM20.0308 4H15.1519V13.0605H20.0308V4ZM25.6064 18.6362H30.4854V27.6968H25.6064V18.6362ZM30.4854 4H25.6064V13.0605H30.4854V4ZM25.6064 32.5757H30.4854V41.6362H25.6064V32.5757ZM40.6973 18.6362H35.8184V27.6968H40.6973V18.6362ZM46.8184 18.6362H51.6973V27.6968H46.8184V18.6362ZM62.6973 18.6362H57.8184V27.6968H62.6973V18.6362ZM69 18.6362H73.8789V27.6968H69V18.6362ZM84.8789 18.6362H80V27.6968H84.8789V18.6362ZM91 18.6362H95.8789V27.6968H91V18.6362ZM51.6973 4H46.8184V13.0605H51.6973V4ZM35.8184 4H40.6973V13.0605H35.8184V4ZM51.6973 32.5757H46.8184V41.6362H51.6973V32.5757ZM69 4H73.8789V13.0605H69V4ZM84.8789 4H80V13.0605H84.8789V4ZM57.8184 4H62.6973V13.0605H57.8184V4ZM95.8789 4H91V13.0605H95.8789V4ZM69 32.5757H73.8789V41.6362H69V32.5757ZM84.8789 32.5757H80V41.6362H84.8789V32.5757ZM35.8184 32.5757H40.6973V41.6362H35.8184V32.5757ZM62.6973 32.5757H57.8184V41.6362H62.6973V32.5757ZM91 32.5757H95.8789V41.6362H91V32.5757ZM20.0308 32.5757H15.1519V41.6362H20.0308V32.5757Z' }, props)));
};
var OSAOkayIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#F9DA6E' : _b, props = __rest(_a, ["color"]);
    return (_jsx(BaseSVGIcon, __assign({ color: color, path: 'M4 0C1.79102 0 0 1.79077 0 4V42C0 44.2092 1.79102 46 4 46H96C98.209 46 100 44.2092 100 42V4C100 1.79077 98.209 0 96 0H4ZM4 4H8.87891V13.0605H4V4ZM4 32.5757H8.87891V41.6362H4V32.5757ZM20.0308 4H15.1519V13.0605H20.0308V4ZM25.6064 18.6362H30.4854V27.6968H25.6064V18.6362ZM30.4854 4H25.6064V13.0605H30.4854V4ZM25.6064 32.5757H30.4854V41.6362H25.6064V32.5757ZM40.6973 18.6362H35.8184V27.6968H40.6973V18.6362ZM46.8184 18.6362H51.6973V27.6968H46.8184V18.6362ZM62.6973 18.6362H57.8184V27.6968H62.6973V18.6362ZM69 18.6362H73.8789V27.6968H69V18.6362ZM84.8789 18.6362H80V27.6968H84.8789V18.6362ZM91 18.6362H95.8789V27.6968H91V18.6362ZM40.6973 4H35.8184V13.0605H40.6973V4ZM46.8184 32.5757H51.6973V41.6362H46.8184V32.5757ZM73.8789 4H69V13.0605H73.8789V4ZM80 4H84.8789V13.0605H80V4ZM62.6973 4H57.8184V13.0605H62.6973V4ZM69 32.5757H73.8789V41.6362H69V32.5757ZM40.6973 32.5757H35.8184V41.6362H40.6973V32.5757ZM91 32.5757H95.8789V41.6362H91V32.5757ZM20.0308 32.5757H15.1519V41.6362H20.0308V32.5757Z' }, props)));
};
var OSAPoorIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#FA6F6F' : _b, props = __rest(_a, ["color"]);
    return (_jsx(BaseSVGIcon, __assign({ color: color, path: 'M4 0C1.79102 0 0 1.79077 0 4V42C0 44.2092 1.79102 46 4 46H96C98.209 46 100 44.2092 100 42V4C100 1.79077 98.209 0 96 0H4ZM8.87891 4H4V13.0605H8.87891V4ZM8.87891 32.5757H4V41.6362H8.87891V32.5757ZM25.6064 32.5757H30.4854V41.6362H25.6064V32.5757ZM62.6973 18.6362H57.8184V27.6968H62.6973V18.6362ZM46.8184 32.5757H51.6973V41.6362H46.8184V32.5757ZM73.8789 4H69V13.0605H73.8789V4ZM80 4H84.8789V13.0605H80V4ZM84.8789 32.5757H80V41.6362H84.8789V32.5757Z' }, props)));
};
export { OSAGoodIcon, OSAOkayIcon, OSAPoorIcon };
