import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";

const Button = styled.button`
  width: ${(props) =>
    props.width ? props.width : props.extraWide ? "50%" : "auto"};
  max-height: 2.5em;
  padding: 0.75em 1em !important;
  animation: ${(props) => (props.disabled ? "none" : "fadeIn 0.5s")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  color: ${(props) => (props.disabled ? props.theme.grey6 : props.theme.white)};
  outline: none;
  align-items: center;
  display: flex;
  justify-content: center;
  user-select: none;
  pointer-events: all;
  border: none;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "none"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "2em"};
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  font-size: 1em;
  font-weight: bold;
  margin: ${(props) =>
    props.margin ? props.margin : props.noMargin ? "0" : "8px"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: ${(props) => props.theme.defaultTransition};
  background: ${(props) =>
    props.background ? props.background : props.theme.midBlue};
  &:hover, &:focus {
    background: ${(props) =>
      props.disabled
        ? props.background || props.theme.midBlue
        : props.backgroundHover
        ? props.backgroundHover
        : props.theme.deepBlue};
    transform: ${(props) => (props.disabled ? "none" : "translateY(-1px)")};
    box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  }
  &:active {
    transform: ${(props) => (props.disabled ? "none" : "scale(0.99)")};
    box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  }
`;
const Span = styled.span`
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => props.theme.white} !important;
  }
`;

class MainCTA extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (typeof (this.props.text || "") === "string") {
      this.props.handleClick({
        type: `BUTTON_CLICK_MAIN_${(this.props.text || "")
          .toUpperCase()
          .split(" ")
          .join("_")}`,
      });
    }
    if (typeof this.props.clickAction === "function") {
      this.props.clickAction();
    }
  }

  render() {
    return (
      <Button
        textTransform={this.props.textTransform}
        noMargin={this.props.noMargin}
        onClick={this.handleClick}
        borderRadius={this.props.borderRadius}
        disabled={this.props.disabled}
        type={this.props.type}
        light={this.props.light}
        extraWide={this.props.extraWide}
        width={this.props.width}
        margin={this.props.margin}
        background={this.props.background}
        backgroundHover={this.props.backgroundHover}
      >
        {this.props.icon ? <Span>{this.props.icon}</Span> : null}
        {this.props.text}
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleClick: (arg) => dispatch({ type: arg.type, meta: arg.meta }),
});

export default connect(null, mapDispatchToProps)(MainCTA);
