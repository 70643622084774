export var initialSuggestions = {
    retailers: null,
    brands: null,
    categories: null,
    states: null,
    countries: null,
    channels: null,
    collections: null,
    cities: null,
};
