var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { media } from "constants/responsive/index";
import styled from "styled-components";
import { textColor } from "constants/functions/index";
import { awsPrefix } from "constants/variables";
export var Flex = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: ", ";\n  justify-content: ", ";\n  width: ", ";\n  flex-wrap: ", ";\n  gap: ", ";\n"], ["\n  display: flex;\n  align-items: ", ";\n  justify-content: ", ";\n  width: ", ";\n  flex-wrap: ", ";\n  gap: ", ";\n"])), function (props) { return props.alignItems || "center"; }, function (props) { return props.justifyContent || "center"; }, function (props) { return props.width || "100%"; }, function (props) { return props.flexWrap || "nowrap"; }, function (props) { return props.gap || undefined; });
export var Padding = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n  width: 100%;\n  box-sizing: border-box;\n  ", ";\n"], ["\n  padding: ", ";\n  width: 100%;\n  box-sizing: border-box;\n  ", ";\n"])), function (props) { return (props.amount ? props.amount : "1em"); }, media.mobile(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 1em;\n  "], ["\n    padding: 1em;\n  "]))));
export var Group = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  min-width: 25%;\n  justify-content: center;\n  align-items: center;\n  ", ";\n"], ["\n  display: flex;\n  min-width: 25%;\n  justify-content: center;\n  align-items: center;\n  ", ";\n"])), media.mobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    min-width: auto;\n    justify-content: space-evenly;\n    padding: 0.5em;\n    .menuOptionSpacer {\n      margin: 0 0.25em;\n    }\n  "], ["\n    width: 100%;\n    min-width: auto;\n    justify-content: space-evenly;\n    padding: 0.5em;\n    .menuOptionSpacer {\n      margin: 0 0.25em;\n    }\n  "]))));
export var TextButton = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 0.5em 0.75em;\n  z-index: 1;\n  background: ", ";\n  display: flex;\n  margin: 0.25em;\n  cursor: pointer;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  border-radius: 0.25em;\n  transition: ", ";\n  align-items: center;\n  color: ", ";\n  svg {\n    font-size: 0.8em;\n    margin-right: ", ";\n  }\n  &:hover {\n    border-color: ", ";\n    color: ", ";\n  }\n}\n"], ["\n  padding: 0.5em 0.75em;\n  z-index: 1;\n  background: ", ";\n  display: flex;\n  margin: 0.25em;\n  cursor: pointer;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  border-radius: 0.25em;\n  transition: ", ";\n  align-items: center;\n  color: ", ";\n  svg {\n    font-size: 0.8em;\n    margin-right: ", ";\n  }\n  &:hover {\n    border-color: ", ";\n    color: ", ";\n  }\n}\n"])), function (props) { return props.theme.white; }, function (props) { return props.theme.grey5; }, function (props) { return props.theme.fastTransition; }, function (props) { return props.theme.grey2; }, function (props) { return (props.noSvgMargin ? "0" : "0.5em"); }, function (props) { return props.theme.grey4; }, function (props) { return props.theme.grey0; });
export var Spacer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 12em;\n"], ["\n  padding: 12em;\n"])));
export var TopBarWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: ", ";\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  z-index: 1;\n  box-sizing: border-box;\n  height: 3.25em;\n  z-index: 3;\n  overflow: hidden;\n  ", ";\n"], ["\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: ", ";\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  z-index: 1;\n  box-sizing: border-box;\n  height: 3.25em;\n  z-index: 3;\n  overflow: hidden;\n  ", ";\n"])), function (props) { return (props.padding ? props.padding : "unset"); }, function (props) { return props.theme.white; }, function (props) { return props.theme.grey5; }, media.mobile(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    padding: ", ";\n  "], ["\n    padding: ", ";\n  "])), function (props) {
    return props.paddingMobile ? props.paddingMobile : "unset";
}));
export var ViewerDivider = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  margin: 0 0.5em;\n  border-color: ", ";\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-color: #e1e8ee;\n  padding-top: 0.5em;\n"], ["\n  width: 100%;\n  margin: 0 0.5em;\n  border-color: ", ";\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-color: #e1e8ee;\n  padding-top: 0.5em;\n"])), function (props) { return props.theme.grey5; });
export var StyledSelect = styled.select(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0.75em 1em;\n  border-radius: 0.5em;\n  border: none;\n  width: 100%;\n  appearance: none;\n  cursor: pointer;\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n  margin-left: 0;\n  margin-right: 0.25em;\n  color: ", ";\n  background: ", ";\n"], ["\n  padding: 0.75em 1em;\n  border-radius: 0.5em;\n  border: none;\n  width: 100%;\n  appearance: none;\n  cursor: pointer;\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n  margin-left: 0;\n  margin-right: 0.25em;\n  color: ", ";\n  background: ", ";\n"])), function (props) { return props.theme.grey2; }, function (props) { return props.theme.white; });
export var SkeletonContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 0.5em;\n  display: flex;\n  width: 100%;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  padding: 0.5em;\n  display: flex;\n  width: 100%;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
export var BottomP = styled.p(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding-bottom: 10em;\n  color: ", ";\n  text-align: center;\n"], ["\n  padding-bottom: 10em;\n  color: ", ";\n  text-align: center;\n"])), function (props) { return props.theme.grey4; });
export var RedCircle = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: ", ";\n  border-radius: 100%;\n  min-height: 2em;\n  min-width: 2em;\n  margin-right: 0.75em;\n  color: white;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: ", ";\n  border-radius: 100%;\n  min-height: 2em;\n  min-width: 2em;\n  margin-right: 0.75em;\n  color: white;\n"])), function (props) { return props.theme.deepBlue; });
export var RuleContainer = styled(Flex)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  background: ", ";\n  animation: fadeIn 1s;\n  transition: ", ";\n  border-radius: ", ";\n"], ["\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  background: ", ";\n  animation: fadeIn 1s;\n  transition: ", ";\n  border-radius: ", ";\n"])), function (props) {
    return props.pending
        ? props.theme.grey6
        : props.theme.pastelBlue;
}, function (props) { return props.theme.defaultTransition; }, function (props) { return props.advanced ? "1em" : "0.5em"; });
export var PaddedFlex = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1em;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1em;\n  width: 100%;\n"])));
export var SpaceBetween = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n"])));
export var DividerLogo = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  border-right: 1px solid ", ";\n  margin: 0 0.75em;\n  height: 1.5em;\n"], ["\n  border-right: 1px solid ", ";\n  margin: 0 0.75em;\n  height: 1.5em;\n"])), function (props) { return props.theme.grey5; });
export var DiscoverContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 100%;\n  animation: fadeIn 0.5s;\n  padding: 0 1em 2em 1em;\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  width: 100%;\n  animation: fadeIn 0.5s;\n  padding: 0 1em 2em 1em;\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var FlyerSubMenu = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  padding: 1em;\n  width: 100%;\n  box-shadow: ", ";\n  display: flex;\n  font-size: 0.8em;\n  background: ", ";\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  padding: 1em;\n  width: 100%;\n  box-shadow: ", ";\n  display: flex;\n  font-size: 0.8em;\n  background: ", ";\n  align-items: center;\n  justify-content: space-between;\n"])), function (props) { return props.theme.subtleBoxShadow; }, function (props) { return props.theme.grey1; });
export var CardSpacing = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  padding: 1%;\n  box-sizing: border-box;\n  display: flex;\n  position: relative;\n  ", "\n\n  &.stories-row {\n    width: 100%;\n    padding: 0;\n    transition: all 0.2s ease-in-out;\n\n    > div:first-child {\n      width: 100%;\n      overflow: auto;\n    }\n  }\n"], ["\n  padding: 1%;\n  box-sizing: border-box;\n  display: flex;\n  position: relative;\n  ", "\n\n  &.stories-row {\n    width: 100%;\n    padding: 0;\n    transition: all 0.2s ease-in-out;\n\n    > div:first-child {\n      width: 100%;\n      overflow: auto;\n    }\n  }\n"])), media.mobile(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    padding: 10px 2px;\n  "], ["\n    padding: 10px 2px;\n  "]))));
export var SettingsInnerContainer = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  display: flex;\n  pointer-events: all;\n  background: inherit;\n  box-shadow: ", ";\n  justify-content: ", ";\n  padding: 0.75em;\n  width: 100%;\n  align-items: center;\n  ", "\n"], ["\n  display: flex;\n  pointer-events: all;\n  background: inherit;\n  box-shadow: ", ";\n  justify-content: ", ";\n  padding: 0.75em;\n  width: 100%;\n  align-items: center;\n  ", "\n"])), function (props) { return props.theme.sortOfSubtleBoxShadow; }, function (props) { return props.justifyContent || "center"; }, media.mobile(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    font-size: 0.8em;\n    flex-wrap: wrap;\n  "], ["\n    font-size: 0.8em;\n    flex-wrap: wrap;\n  "]))));
export var SettingsContainer = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  font-size: 0.65em;\n  animation: fadeIn 0.5s;\n  box-sizing: border-box;\n  position: ", ";\n  z-index: 3;\n  pointer-events: none;\n  background: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  font-size: 0.65em;\n  animation: fadeIn 0.5s;\n  box-sizing: border-box;\n  position: ", ";\n  z-index: 3;\n  pointer-events: none;\n  background: ", ";\n"])), function (props) { return props.position || "relative"; }, function (props) { return props.primaryColor || props.theme.grey3; });
export var Layer = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  position: fixed;\n  animation: fadeIn 0.2s;\n  z-index: 3;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  background: rgba(0, 0, 0, 0.5);\n"], ["\n  position: fixed;\n  animation: fadeIn 0.2s;\n  z-index: 3;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  background: rgba(0, 0, 0, 0.5);\n"])));
export var Container = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  height: 1.5em;\n  width: ", ";\n  border-radius: 2em;\n  animation: ", ";\n  cursor: pointer;\n  position: relative;\n  user-select: none;\n  display: flex;\n  justify-content: ", ";\n  box-sizing: border-box;\n  text-align: center;\n  font-weight: bold;\n  transition: ", ";\n  align-items: center;\n  margin: 0.5em;\n  min-width: ", ";\n  text-transform: uppercase;\n  padding: 1.74em;\n  background: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  svg {\n    min-width: ", ";\n    min-height: ", ";\n    height: ", ";\n    margin-right: ", ";\n    margin-left: ", ";\n  }\n  &:hover {\n    box-shadow: ", ";\n    color: ", ";\n    background: ", ";\n    svg {\n      fill: ", ";\n    }\n  }\n  &:active {\n    transform: translateY(-2px);\n    background: white;\n  }\n  ", ";\n"], ["\n  height: 1.5em;\n  width: ", ";\n  border-radius: 2em;\n  animation: ", ";\n  cursor: pointer;\n  position: relative;\n  user-select: none;\n  display: flex;\n  justify-content: ", ";\n  box-sizing: border-box;\n  text-align: center;\n  font-weight: bold;\n  transition: ", ";\n  align-items: center;\n  margin: 0.5em;\n  min-width: ", ";\n  text-transform: uppercase;\n  padding: 1.74em;\n  background: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  svg {\n    min-width: ", ";\n    min-height: ", ";\n    height: ", ";\n    margin-right: ", ";\n    margin-left: ", ";\n  }\n  &:hover {\n    box-shadow: ", ";\n    color: ", ";\n    background: ", ";\n    svg {\n      fill: ", ";\n    }\n  }\n  &:active {\n    transform: translateY(-2px);\n    background: white;\n  }\n  ", ";\n"])), function (props) { return (props.circle ? "1.5em" : props.wide ? "100%" : "auto"); }, function (props) { return (props.animation ? props.animation : "none"); }, function (props) {
    return props.justifyContent ? props.justifyContent : "center";
}, function (props) { return props.theme.defaultTransition; }, function (props) { return (props.circle ? "auto" : "4em"); }, function (props) {
    return props.primaryColor
        ? props.primaryColor
        : props.active
            ? props.theme.grey6
            : "rgba(0,0,0,0.45)";
}, function (props) {
    return props.textColor
        ? props.textColor
        : props.active
            ? props.theme.grey1
            : "white";
}, function (props) {
    return props.boxShadow ? props.boxShadow : props.theme.notSubtleBoxShadow;
}, function (props) { return (props.svgMinSize ? props.svgMinSize : "1em"); }, function (props) { return (props.svgMinSize ? props.svgMinSize : "1em"); }, function (props) { return (props.svgSize ? props.svgSize : "1em"); }, function (props) {
    return props.svgMarginRight
        ? props.svgMarginRight
        : props.circle
            ? "0.25em"
            : "0.75em";
}, function (props) { return (props.circle ? "0.25em" : "0"); }, function (props) { return props.theme.notSubtleBoxShadow; }, function (props) {
    return props.hoverTextColor ? props.hoverTextColor : props.theme.grey1;
}, function (props) {
    return props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.grey6;
}, function (props) {
    return props.hoverTextColor
        ? props.hoverTextColor
        : props.active
            ? "auto"
            : props.theme.grey1;
}, media.mobile(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    height: auto;\n    max-height: 4em;\n    margin: 0.25em;\n    align-self: ", ";\n    svg {\n      margin-bottom: 0;\n      margin-top: 0 !important;\n    }\n    .iconCaret {\n      display: none;\n    }\n  "], ["\n    height: auto;\n    max-height: 4em;\n    margin: 0.25em;\n    align-self: ", ";\n    svg {\n      margin-bottom: 0;\n      margin-top: 0 !important;\n    }\n    .iconCaret {\n      display: none;\n    }\n  "])), function (props) {
    return props.mobileAlign ? props.mobileAlign : "stretch";
}));
export var TextInput = styled.input(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  padding: 1em;\n  font-size: 1em;\n  font-weight: bold;\n  margin: 0 0 1em 0;\n  outline: none;\n  text-transform: none;\n  width: 100%;\n  border: 1px solid ", ";\n  box-sizing: border-box;\n  &::placeholder {\n    color: ", ";\n  }\n"], ["\n  padding: 1em;\n  font-size: 1em;\n  font-weight: bold;\n  margin: 0 0 1em 0;\n  outline: none;\n  text-transform: none;\n  width: 100%;\n  border: 1px solid ", ";\n  box-sizing: border-box;\n  &::placeholder {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grey4; }, function (props) { return props.theme.grey4; });
export var DropdownOptions = styled.div(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  box-shadow: ", ";\n  position: absolute;\n  animation: fadeIn 0.25s;\n  cursor: auto;\n  background: ", ";\n  border-radius: 2em;\n  overflow: hidden;\n  top: 4em;\n  left: ", ";\n  right: ", ";\n  min-width: ", ";\n  width: fit-content;\n  z-index: 1;\n  max-height: 50em;\n  overflow: auto;\n  ", ";\n"], ["\n  box-shadow: ", ";\n  position: absolute;\n  animation: fadeIn 0.25s;\n  cursor: auto;\n  background: ", ";\n  border-radius: 2em;\n  overflow: hidden;\n  top: 4em;\n  left: ", ";\n  right: ", ";\n  min-width: ", ";\n  width: fit-content;\n  z-index: 1;\n  max-height: 50em;\n  overflow: auto;\n  ", ";\n"])), function (props) { return props.theme.notSubtleBoxShadow; }, function (props) {
    return props.primaryColor ? props.primaryColor : props.theme.grey2;
}, function (props) { return (props.left ? props.left : "auto"); }, function (props) { return (props.right ? props.right : "auto"); }, function (props) { return (props.minWidth ? props.minWidth : "10em"); }, media.mobile(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n    top: 5em;\n    min-width: 60vw;\n    div {\n      margin: 0.25em 0;\n    }\n  "], ["\n    top: 5em;\n    min-width: 60vw;\n    div {\n      margin: 0.25em 0;\n    }\n  "]))));
export var Mask = styled.div(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  background: rgba(0, 0, 0, 0.1);\n  padding: 0.5em;\n  display: flex;\n  border-radius: 0.5em;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n"], ["\n  background: rgba(0, 0, 0, 0.1);\n  padding: 0.5em;\n  display: flex;\n  border-radius: 0.5em;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n"])));
export var ChildMask = styled.div(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.3);\n  z-index: 1;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.3);\n  z-index: 1;\n"])));
export var TooltipText = styled.div(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  background: #393e42;\n  max-width: ", ";\n  text-align: center;\n  animation: fadeIn 0.5s;\n  color: white;\n  margin: 0.25em;\n  padding: 0.5em;\n  border-radius: 0.5em;\n  font-size: 0.8em;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  .keyboardShortcut {\n    display: flex;\n    margin-left: 0.5em;\n    padding: 0.25em 0.5em;\n    border-radius: 0.25em;\n    background: gray;\n  }\n"], ["\n  background: #393e42;\n  max-width: ", ";\n  text-align: center;\n  animation: fadeIn 0.5s;\n  color: white;\n  margin: 0.25em;\n  padding: 0.5em;\n  border-radius: 0.5em;\n  font-size: 0.8em;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  .keyboardShortcut {\n    display: flex;\n    margin-left: 0.5em;\n    padding: 0.25em 0.5em;\n    border-radius: 0.25em;\n    background: gray;\n  }\n"])), function (props) { return (props.maxWidth ? props.maxWidth : "200px"); });
export var Option = styled.div(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-align: left;\n  svg {\n    padding-right: 0.5em;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  text-align: left;\n  svg {\n    padding-right: 0.5em;\n  }\n"])));
export var Title = styled.h2(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  font-weight: bold;\n  width: 100%;\n  padding: 0 0.5em;\n  color: ", ";\n"], ["\n  font-weight: bold;\n  width: 100%;\n  padding: 0 0.5em;\n  color: ", ";\n"])), function (props) { return props.theme.grey2; });
export var LandingPageTitle = styled.h3(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  font-weight: bold;\n  text-transform: uppercase;\n  margin: 0;\n  padding: 0 0.8em 0 0.5em;\n  animation: fadeIn 0.5s;\n  min-width: fit-content;\n  word-break: break-word;\n"], ["\n  font-weight: bold;\n  text-transform: uppercase;\n  margin: 0;\n  padding: 0 0.8em 0 0.5em;\n  animation: fadeIn 0.5s;\n  min-width: fit-content;\n  word-break: break-word;\n"])));
export var H3 = styled.h3(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0;\n  padding: 1em;\n  ", "\n"], ["\n  color: ", ";\n  margin: 0;\n  padding: 1em;\n  ", "\n"])), function (props) { return (props.color ? props.color : props.theme.grey2); }, media.mobile(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n    width: 100%;\n    text-align: center;\n  "], ["\n    width: 100%;\n    text-align: center;\n  "]))));
export var WhiteBox = styled.div(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  animation: fadeIn 1s;\n  justify-content: space-between;\n  align-items: center;\n  background: ", ";\n  border-radius: 0.5em;\n  box-shadow: ", ";\n  display: flex;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  padding: 0.5em;\n  margin-bottom: 1em;\n  ", ";\n"], ["\n  animation: fadeIn 1s;\n  justify-content: space-between;\n  align-items: center;\n  background: ", ";\n  border-radius: 0.5em;\n  box-shadow: ", ";\n  display: flex;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  padding: 0.5em;\n  margin-bottom: 1em;\n  ", ";\n"])), function (props) { return props.theme.white; }, function (props) { return props.theme.subtleBoxShadow; }, media.mobile(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n    justify-content: center;\n  "], ["\n    justify-content: center;\n  "]))));
export var Img = styled.img(templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  transition: ", ";\n  user-select: none;\n  max-width: 2.5em;\n  background: white;\n  min-width: 1em;\n  height: ", ";\n  padding-bottom: 0;\n  margin-right: 0.5em;\n  opacity: 0.7;\n  border: ", ";\n  object-fit: ", ";\n  ", ";\n"], ["\n  transition: ", ";\n  user-select: none;\n  max-width: 2.5em;\n  background: white;\n  min-width: 1em;\n  height: ", ";\n  padding-bottom: 0;\n  margin-right: 0.5em;\n  opacity: 0.7;\n  border: ", ";\n  object-fit: ", ";\n  ", ";\n"])), function (props) { return props.theme.defaultTransition; }, function (props) { return (props.large ? "2.5em" : "1em"); }, function (props) {
    return props.border ? "1px solid ".concat(function (props) { return props.theme.grey2; }) : "none";
}, function (props) { return (props.border ? "fill" : "contain"); }, media.mobile(templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n    opacity: 1;\n  "], ["\n    opacity: 1;\n  "]))));
var ReTooltipContainer = styled.div(templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  border-radius: 2em;\n  padding: 0.5em 1em;\n  background: white;\n  font-size: 0.8em;\n  animation: fadeIn 0.5s;\n"], ["\n  border-radius: 2em;\n  padding: 0.5em 1em;\n  background: white;\n  font-size: 0.8em;\n  animation: fadeIn 0.5s;\n"])));
var ReTooltipText = styled.div(templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
export var CustomTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label;
    if (active && payload && payload.length && payload[0].payload) {
        return (_jsx(ReTooltipContainer, { style: {
                background: payload[0].payload.fill
                    ? payload[0].payload.fill
                    : "rgb".concat(payload[0].payload.primary_color),
            }, children: _jsx(ReTooltipText, { style: {
                    color: payload[0].payload.stroke
                        ? payload[0].payload.stroke
                        : textColor(payload[0].payload.primary_color),
                }, children: payload[0].payload.name }) }));
    }
    return null;
};
export var Glow = styled.div(templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  box-shadow: 0px 0px 30px 40px ", ";\n  width: 100%;\n  bottom: 0;\n  z-index: 1;\n  transition: ", ";\n  position: absolute;\n  opacity: ", ";\n"], ["\n  box-shadow: 0px 0px 30px 40px ", ";\n  width: 100%;\n  bottom: 0;\n  z-index: 1;\n  transition: ", ";\n  position: absolute;\n  opacity: ", ";\n"])), function (props) { return props.theme.veryLightGrey; }, function (props) { return props.theme.defaultTransition; }, function (props) { return (props.hide ? 0 : 1); });
export var MainPageWrapper = styled.div(templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  overflow-y: visible;\n  height: 100%;\n  position: relative;\n"], ["\n  overflow-y: visible;\n  height: 100%;\n  position: relative;\n"])));
export var MenuLeft = styled.div(templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  justify-content: flex-start;\n  display: flex;\n  align-items: center;\n  animation: fadeIn 1s;\n  input {\n    display: none;\n  }\n  ", ";\n"], ["\n  justify-content: flex-start;\n  display: flex;\n  align-items: center;\n  animation: fadeIn 1s;\n  input {\n    display: none;\n  }\n  ", ";\n"])), media.mobile(templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n    flex-wrap: wrap;\n    font-size: 0.8em;\n    width: 100%;\n    justify-content: center;\n  "], ["\n    flex-wrap: wrap;\n    font-size: 0.8em;\n    width: 100%;\n    justify-content: center;\n  "]))));
export var MenuText = styled.span(templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  animation: fadeIn 1s;\n  margin: 0 0.5em;\n  font-size: 1.5em;\n  padding: 0;\n  font-weight: bold;\n  color: #ffffff;\n"], ["\n  animation: fadeIn 1s;\n  margin: 0 0.5em;\n  font-size: 1.5em;\n  padding: 0;\n  font-weight: bold;\n  color: #ffffff;\n"])));
export var Divider = styled.div(templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  border-left: 1px solid ", ";\n  margin-left: 1em;\n  padding-left: 1em;\n  height: 100%;\n"], ["\n  border-left: 1px solid ", ";\n  margin-left: 1em;\n  padding-left: 1em;\n  height: 100%;\n"])), function (props) { return props.theme.grey5; });
export var Dots = styled.div(templateObject_52 || (templateObject_52 = __makeTemplateObject(["\n  background-image: radial-gradient(\n    rgba(255, 255, 255, 0.15) 0.5px,\n    rgba(0, 0, 0, 0.05) 0.5px\n  );\n  background-size: 15px 15px;\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n"], ["\n  background-image: radial-gradient(\n    rgba(255, 255, 255, 0.15) 0.5px,\n    rgba(0, 0, 0, 0.05) 0.5px\n  );\n  background-size: 15px 15px;\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n"])));
export var TrendDot = styled.div(templateObject_53 || (templateObject_53 = __makeTemplateObject(["\n  border-radius: 50%;\n  min-height: 0.8em;\n  min-width: 0.8em;\n  margin-right: 0.5em;\n"], ["\n  border-radius: 50%;\n  min-height: 0.8em;\n  min-width: 0.8em;\n  margin-right: 0.5em;\n"])));
export var Name = styled.p(templateObject_54 || (templateObject_54 = __makeTemplateObject(["\n  margin: 0;\n  width: 100%;\n  min-width: fit-content;\n  text-align: left;\n"], ["\n  margin: 0;\n  width: 100%;\n  min-width: fit-content;\n  text-align: left;\n"])));
export var MenuName = styled.div(templateObject_55 || (templateObject_55 = __makeTemplateObject(["\n  user-select: none;\n  padding: 0.25em;\n  font-weight: bold;\n  text-align: left;\n"], ["\n  user-select: none;\n  padding: 0.25em;\n  font-weight: bold;\n  text-align: left;\n"])));
export var MenuContainer = styled.div(templateObject_57 || (templateObject_57 = __makeTemplateObject(["\n  display: flex;\n  width: ", ";\n  margin: ", ";\n  animation: fadeIn 0.5s;\n  height: ", ";\n  align-items: center;\n  justify-content: ", ";\n  padding: ", ";\n  cursor: ", ";\n  color: ", ";\n  border-radius: ", ";\n  transition: ", ";\n  background: ", ";\n  svg {\n    transition: ", ";\n    margin: ", ";\n    min-width: 1em;\n    fill: ", ";\n  }\n  &:hover {\n    .shortcut-keys {\n      background: ", ";\n    }\n    color: ", ";\n    .FaAngleDown {\n      transform: ", ";\n    }\n    svg {\n      fill: ", ";\n    }\n  }\n  &:active {\n    transform: ", ";\n  }\n  ", ";\n"], ["\n  display: flex;\n  width: ", ";\n  margin: ", ";\n  animation: fadeIn 0.5s;\n  height: ", ";\n  align-items: center;\n  justify-content: ", ";\n  padding: ", ";\n  cursor: ", ";\n  color: ", ";\n  border-radius: ", ";\n  transition: ", ";\n  background: ", ";\n  svg {\n    transition: ", ";\n    margin: ", ";\n    min-width: 1em;\n    fill: ", ";\n  }\n  &:hover {\n    .shortcut-keys {\n      background: ", ";\n    }\n    color: ", ";\n    .FaAngleDown {\n      transform: ", ";\n    }\n    svg {\n      fill: ", ";\n    }\n  }\n  &:active {\n    transform: ", ";\n  }\n  ", ";\n"])), function (props) { return (props.onboarding ? "100%" : "auto"); }, function (props) { return (props.full ? "0" : "0 0.25em"); }, function (props) { return (props.height ? props.height : "unset"); }, function (props) {
    return props.justifyContent ? props.justifyContent : "flex-start";
}, function (props) { return (props.height ? "0 0.6em" : "0.6em"); }, function (props) { return (props.onboarding ? "auto" : "pointer"); }, function (props) { return (props.active ? props.theme.white : props.theme.grey2); }, function (props) { return (props.full ? "0" : "2em"); }, function (props) { return props.theme.fastTransition; }, function (props) {
    return props.noHover ? "none" : props.active ? props.theme.grey2 : "none";
}, function (props) { return props.theme.fastTransition; }, function (props) { return (props.full ? "0 0.25em" : 0); }, function (props) {
    return props.whiteIcon || props.active
        ? props.theme.white
        : props.fill
            ? props.fill
            : props.theme.grey2;
}, function (props) { return props.theme.grey2; }, function (props) { return (props.active ? props.theme.white : props.theme.grey0); }, function (props) { return (props.active ? "none" : "translateY(2px)"); }, function (props) {
    return props.whiteIcon || props.active
        ? props.theme.white
        : props.fillHover
            ? props.fillHover
            : props.theme.grey0;
}, function (props) { return (props.onboarding ? "none" : "translateY(-2px)"); }, media.mobile(templateObject_56 || (templateObject_56 = __makeTemplateObject(["\n    margin: auto;\n    padding: 0.5em;\n  "], ["\n    margin: auto;\n    padding: 0.5em;\n  "]))));
export var CopyButton = styled.button(templateObject_58 || (templateObject_58 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  transition: ", ";\n  padding: 0.75em;\n  font-weight: bold;\n  min-width: 5em;\n  cursor: pointer;\n  border: none;\n  font-family: ", ";\n  font-size: 1em;\n  border-left: 1px solid ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  transition: ", ";\n  padding: 0.75em;\n  font-weight: bold;\n  min-width: 5em;\n  cursor: pointer;\n  border: none;\n  font-family: ", ";\n  font-size: 1em;\n  border-left: 1px solid ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, copied = _a.copied;
    return copied ? theme.midGreen : theme.midBlue;
}, function (_a) {
    var theme = _a.theme;
    return theme.defaultTransition;
}, function (_a) {
    var theme = _a.theme;
    return theme.font;
}, function (_a) {
    var theme = _a.theme, copied = _a.copied;
    return copied ? theme.midGreen : theme.grey4;
}, function (_a) {
    var theme = _a.theme, copied = _a.copied;
    return copied ? theme.midGreen : theme.midBlue;
}, function (_a) {
    var theme = _a.theme;
    return theme.midGreen;
});
export var LoginOption = styled.div(templateObject_59 || (templateObject_59 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 0.25em;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin: 0.25em;\n  padding: 1em;\n  width: 100%;\n  color: ", ";\n  cursor: pointer;\n  transition: ", ";\n  img {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 1em;\n  }\n  svg {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 1em;\n    fill: ", ";\n  }\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"], ["\n  border: 1px solid ", ";\n  border-radius: 0.25em;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin: 0.25em;\n  padding: 1em;\n  width: 100%;\n  color: ", ";\n  cursor: pointer;\n  transition: ", ";\n  img {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 1em;\n  }\n  svg {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 1em;\n    fill: ", ";\n  }\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"])), function (props) { return props.theme.grey4; }, function (props) { return props.theme.grey2; }, function (props) { return props.theme.fastTransition; }, function (props) { return props.theme.grey2; }, function (props) { return props.theme.grey6; }, function (props) { return props.theme.grey1; }, function (props) { return props.theme.grey5; });
export var FullWidth = styled.div(templateObject_60 || (templateObject_60 = __makeTemplateObject(["\n  width: 100%;\n  display: ", ";\n"], ["\n  width: 100%;\n  display: ", ";\n"])), function (props) { return props.display; });
export var LandingPageProfilePic = styled.div(templateObject_61 || (templateObject_61 = __makeTemplateObject(["\n  transition: ", ";\n  box-shadow: ", ";\n  margin: 0.25em;\n  border-radius: 5px;\n  background-color: ", ";\n  height: 3em;\n  width: 3em;\n  border: 1px solid ", ";\n  background-image: ", ";\n  background-position: center;\n  cursor: pointer;\n  background-size: 60%;\n  padding: 0.5em;\n  background-repeat: no-repeat;\n  &:hover {\n      border-color: ", ";\n      box-shadow: ", ";\n      background-color: ", ";\n  }\n"], ["\n  transition: ", ";\n  box-shadow: ", ";\n  margin: 0.25em;\n  border-radius: 5px;\n  background-color: ", ";\n  height: 3em;\n  width: 3em;\n  border: 1px solid ", ";\n  background-image: ", ";\n  background-position: center;\n  cursor: pointer;\n  background-size: 60%;\n  padding: 0.5em;\n  background-repeat: no-repeat;\n  &:hover {\n      border-color: ", ";\n      box-shadow: ", ";\n      background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fastTransition;
}, function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return selected ? theme.sortOfSubtleBoxShadow : theme.subtleBoxShadow;
}, function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return selected ? theme.white : theme.grey5;
}, function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return selected ? theme.grey1 : theme.white;
}, function (_a) {
    var img = _a.img, source = _a.source;
    return img && source
        ? "url(\"".concat(awsPrefix).concat(source, "/").concat(img, "\")")
        : undefined;
}, function (_a) {
    var theme = _a.theme;
    return theme.grey1;
}, function (_a) {
    var theme = _a.theme;
    return theme.sortOfSubtleBoxShadow;
}, function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return selected ? theme.white : theme.grey6;
});
export var BetaPill = styled.span(templateObject_62 || (templateObject_62 = __makeTemplateObject(["\n  background-color: ", ";\n  color: white;\n  font-size: 0.6rem;\n  font-weight: 600;\n  padding: 2px 6px;\n  border-radius: 10px;\n  margin-left: 0.5em;\n"], ["\n  background-color: ", ";\n  color: white;\n  font-size: 0.6rem;\n  font-weight: 600;\n  padding: 2px 6px;\n  border-radius: 10px;\n  margin-left: 0.5em;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.midPurple;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51, templateObject_52, templateObject_53, templateObject_54, templateObject_55, templateObject_56, templateObject_57, templateObject_58, templateObject_59, templateObject_60, templateObject_61, templateObject_62;
