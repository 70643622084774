import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
export var listenerMiddleware = createListenerMiddleware();
export var startAppListening = listenerMiddleware.startListening.withTypes();
export var addAppListener = addListener.withTypes();
// Register Listeners
import { addModalListeners } from "@redux/slices/modalSlice";
import { addPrefetchedDataListener } from "@redux/slices/feedFilterRulesSlice";
import { addAutocompleteSuggestionsListener } from "@redux/slices/autocompleteSuggestionsSlice";
import { addLoadingBarListeners } from "@redux/api/loadingBar";
import { addPlanogramStitchImagesListener } from "@redux/api/endpoints/planogram";
import { addToastListener } from "@redux/api/toasts";
import { addStoriesListener } from '@redux/slices/storiesSlice';
addModalListeners(startAppListening);
addPrefetchedDataListener(startAppListening);
addAutocompleteSuggestionsListener(startAppListening);
addLoadingBarListeners(startAppListening);
addPlanogramStitchImagesListener(startAppListening);
addToastListener(startAppListening);
addStoriesListener(startAppListening);
