import { logout } from "@redux/actions/logout";
import { createSlice } from "@reduxjs/toolkit";
import { fetchSyndicatedCollections, updateSyndicatedCollectionPromotion } from "@redux/api/endpoints/admin";
var initialState = [];
var syndicatedCollectionsSlice = createSlice({
    name: 'syndicatedCollections',
    initialState: initialState,
    reducers: { /* No reducers */},
    extraReducers: function (builder) {
        builder
            .addCase(fetchSyndicatedCollections.fulfilled, function (_state, action) {
            return action.payload;
        })
            .addCase(updateSyndicatedCollectionPromotion.pending, function (state, action) {
            var collection = state.find(function (collection) { return collection.id === action.meta.arg.body.collection_id; });
            if (collection) {
                collection.is_promoted = !collection.is_promoted;
            }
        })
            .addCase(logout, function () { return initialState; });
    }
});
export default syndicatedCollectionsSlice.reducer;
