import { adminPlanId } from "constants/variables";
/**
 Temporary selectors until dataUser is rewritten as a slice
 */
export var selectUserData = function (state) {
    return state.dataUser;
};
export var selectUserPlan = function (state) {
    var _a;
    return (_a = selectUserData(state)) === null || _a === void 0 ? void 0 : _a.plan_id;
};
export var selectIsLoggedIn = function (state) {
    return !!selectUserPlan(state);
};
export var selectIsFreePlanUser = function (state) {
    return (selectUserPlan(state) || 1) == 1;
};
export var selectIsEnterpriseUser = function (state) {
    return !selectIsFreePlanUser(state);
};
export var selectIsAdminUser = function (state) {
    return selectUserPlan(state) === adminPlanId;
};
