var _a, _b;
import { getBaseComponentFromActionType, getComponentName, isApiThunkAction, isApiThunkFailure } from "@redux/api/createApiThunk";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { emptyArray } from "constants/variables";
var initialState = {};
var apiCallsSlice = createSlice({
    name: "apiCalls",
    initialState: initialState,
    reducers: {
        clearApiCallStatus: function (state, action) {
            var _a;
            var _b = action.payload, component = _b.component, requestId = _b.requestId;
            if (((_a = state[component]) === null || _a === void 0 ? void 0 : _a.requestId) === requestId) {
                delete state[component];
            }
        },
        clearApiCallError: function (state, action) {
            var _a;
            if ((_a = state[action.payload]) === null || _a === void 0 ? void 0 : _a.error) {
                delete state[action.payload];
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(isApiThunkAction, function (state, action) {
            var _a = action.meta, component = _a.component, requestId = _a.requestId, requestStatus = _a.requestStatus;
            switch (requestStatus) {
                case "pending":
                    state[component] = {
                        fetching: true,
                        error: null,
                        requestId: requestId
                    };
                    break;
                case "fulfilled":
                    if (state[component]) {
                        state[component].fetching = false;
                        state[component].error = false;
                    }
                    break;
                case "rejected": {
                    if (isApiThunkFailure(action)) {
                        var componentName = component || getComponentName(getBaseComponentFromActionType(action.type), action.meta.arg);
                        if (state[componentName]) {
                            state[componentName].fetching = false;
                            state[componentName].error = action.payload;
                        }
                    }
                    break;
                }
            }
        });
    },
    selectors: {
        selectAPICallIsFetching: function (sliceState, component) { var _a; return ((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.fetching) || false; },
        selectAPICallIsSuccess: function (sliceState, component) { return !!sliceState[component] && !sliceState[component].fetching && sliceState[component].error === false; },
        selectAPICallIsError: function (sliceState, component) { var _a; return !!((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.error); },
        selectAPICallError: function (sliceState, component) { var _a; return !!((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.error) && sliceState[component].error; },
        selectAPICalls: function (sliceState) { return sliceState; }
    }
});
export var clearApiCallStatus = (_a = apiCallsSlice.actions, _a.clearApiCallStatus), clearApiCallError = _a.clearApiCallError;
export var selectAPICallIsFetching = (_b = apiCallsSlice.selectors, _b.selectAPICallIsFetching), selectAPICallIsSuccess = _b.selectAPICallIsSuccess, selectAPICallIsError = _b.selectAPICallIsError, selectAPICallError = _b.selectAPICallError, selectAPICalls = _b.selectAPICalls;
export default apiCallsSlice.reducer;
export var selectFetchingApiCalls = createSelector([selectAPICalls], function (apiCalls) {
    var fetchingApiCalls = Object.entries(apiCalls)
        .filter(function (_a) {
        var _ = _a[0], status = _a[1];
        return status.fetching;
    })
        .map(function (_a) {
        var component = _a[0], _ = _a[1];
        return component;
    });
    return fetchingApiCalls.length
        ? fetchingApiCalls
        : emptyArray;
});
export var selectIsFetchingByPrefix = createSelector([
    selectFetchingApiCalls,
    function (_state, componentPrefix) { return componentPrefix; }
], function (fetchingApiCalls, componentPrefix) {
    return fetchingApiCalls.some(function (component) {
        return component.startsWith(componentPrefix);
    });
});
