import "babel-polyfill";
import "isomorphic-fetch";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { destroyLoginCookieAndLocalStorage } from "constants/functions";
import { apiCall } from "shared/helpers/apiCall";

const showLoadingBar = [
    "FEED_PHOTOS",
    "ANALYTICS_PLANOGRAM_CHECK",
    "FACINGS_SEARCH",
    "PRODUCT_PRICING",
    "PRODUCT_PRICING_MORE",
    "PRIVATE_COLLECTION",
    "DIRECTORY_BRANDS",
    "DIRECTORY_RETAILERS",
    "DIRECTORY_COUNTRIES",
];

// worker saga: makes the api call when watcher saga sees the action
function* workerSaga({ payload }) {
    const { component } = payload;
    const needsLoadingBar = showLoadingBar.includes(component) || component.includes("ANALYTICS_REPORT_GET");

    if (needsLoadingBar) yield put(showLoading());
    try {
        const data = yield call(apiCall, payload);
        // dispatch a success action to the store with the new data
        yield put({ type: "API_CALL_SUCCESS", payload });
        if (data) {
            yield put({
                type: `${component}_DATA`,
                data: data,  // For compatibility with existing components/reducers
                payload: data,  // Align with redux conventions
                meta: payload  // Provide API call payload
            });
        }
    } catch (error) {
        const { status, message, data } = error;
        yield put({ type: "API_CALL_FAILURE", error: { status, message, data }, payload });

        if (status === 401 && typeof data === "string" && data.includes("auth_failure")) {
          destroyLoginCookieAndLocalStorage();
          yield put({ type: "LOGOUT" });
        }
    } finally {
        if (needsLoadingBar) yield put(hideLoading());
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {
    yield takeEvery("API_CALL_REQUEST", workerSaga);
    yield takeLatest("API_CALL_TAKE_LATEST", workerSaga);
}

