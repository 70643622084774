import { createSlice } from "@reduxjs/toolkit";
import { hideModal } from "@redux/slices/modalSlice";
import { createNewCompany } from "@redux/api/endpoints/companies";
var initialState = null;
var createdCompanySlice = createSlice({
    name: "createdCompany",
    initialState: initialState,
    reducers: {
        createdCompanyReset: function () { return initialState; }
    },
    extraReducers: function (builder) {
        builder
            .addCase(createNewCompany.fulfilled, function (_state, action) {
            return action.payload[0];
        })
            .addCase(hideModal, function () { return initialState; });
    }
});
export var createdCompanyReset = createdCompanySlice.actions.createdCompanyReset;
export default createdCompanySlice.reducer;
